import React, { useEffect, useMemo, useState } from "react";

// Chakra imports
import {
  Box,
  Heading,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
// import { MdAttachMoney, MdOutlinePendingActions } from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";
import { GrSend } from "react-icons/gr";
import { FaBoxesStacked } from "react-icons/fa6";
import { RiContactsBook2Fill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";

import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import DailyTraffic from "views/admin/rtl/components/DailyTraffic";
import apiRequest from "utils/api";
import { BiSolidUserAccount } from "react-icons/bi";
import { useSelector } from "react-redux";

const Home = () => {
  const user = useSelector((state) => state.user?.data);

  // Chakra Color Mode
  const [statistics, setStatistics] = useState({
    messageSent: "جاري التحميل...",
    usageAccounts: "جاري التحميل...",
    exportContact: "جاري التحميل...",
    findingAmount: "جاري التحميل...",
    sections: "جاري التحميل...",
  });
  const secoundyColor = useMemo(() => "#25B697", []);
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  useEffect(() => {
    if (!user?._id) return;

    const fetchData = async () => {
      try {
        const response = await apiRequest(`/statistics/get/${user.token}`);
        if (response.code === 200) {
          setStatistics({
            messageSent: response?.data?.messageSent,
            usageAccounts: response?.data?.usageAccounts,
            exportContact: response?.data?.exportContact,
            findingAmount: response?.data?.findingAmount,
            sections: response?.data?.sections,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [user]);

  const formatNumber = (number = 0) => {
    if (isNaN(number)) {
      return number;
    }

    if (number >= 1e6) return (number / 1e6).toFixed(1) + "M"; // Millions
    if (number >= 1e3) return (number / 1e3).toFixed(1) + "K"; // Thousands
    return number.toString();
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="30px" h="30px" as={GrSend} color={secoundyColor} />
              }
            />
          }
          name="الرسائل المرسله"
          title={statistics.messageSent.toString()}
          value={formatNumber(statistics.messageSent)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="33px"
                  h="33px"
                  as={BiSolidUserAccount}
                  color={secoundyColor}
                />
              }
            />
          }
          name="الحسابات المفعله"
          title={statistics.usageAccounts.toString()}
          value={formatNumber(statistics.usageAccounts)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="33px" h="33px" as={FaUsers} color={secoundyColor} />
              }
            />
          }
          name="الارقام المستخرجه"
          title={statistics.exportContact.toString()}
          value={formatNumber(statistics.exportContact)}
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={FaBoxesStacked}
                  color={secoundyColor}
                />
              }
            />
          }
          name="الاقسام"
          title={statistics.sections.toString()}
          value={formatNumber(statistics.sections)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="33px"
                  h="33px"
                  as={RiContactsBook2Fill}
                  color={secoundyColor}
                />
              }
            />
          }
          name="سحب الروابط"
          title={statistics.findingAmount.toString()}
          value={formatNumber(statistics.findingAmount)}
        />
      </SimpleGrid>

      <Box mb="20px">
        <DailyTraffic numOfMessages={statistics.messageSent} />
      </Box>
    </Box>
  );
};

export default Home;
