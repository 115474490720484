import { useState, useEffect, useCallback } from "react";
import apiRequest from "../utils/api";
import authService from "utils/authService";

const useAuth = (userKeyName = "user") => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  // Function to check authentication status and fetch user data
  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const data = await apiRequest(`/user/get/${token}`);
        if (data.code === 200) {
          setIsAuthenticated(true);
          setUserData(data[userKeyName]);
        } else {
          setIsAuthenticated(false);
          setUserData(null);
        }
      } catch (error) {
        setIsAuthenticated(false);
        setUserData(null);
      }
    } else {
      setIsAuthenticated(false);
      setUserData(null);
    }
    setLoading(false);
  }, [userKeyName]);

  // Login function that checks the token from localStorage and calls checkAuth
  const login = useCallback(async () => {
    setLoading(true);
    try {
      // Check authentication status using the token from localStorage
      await checkAuth();
    } catch (error) {
      console.error("Login failed:", error);
      setIsAuthenticated(false);
      setUserData(null);
    } finally {
      setLoading(false);
    }
  }, [checkAuth]);

  // Logout function to clear authentication state and token
  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setUserData(null);
  }, []);

  // update auth services
  useEffect(() => {
    authService.login = login;
    authService.logout = logout;
  }, [login, logout]);

  // Automatically check authentication on component mount
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return { isAuthenticated, loading, userData };
};

export default useAuth;
