import { Icon, Flex, Text, useColorModeValue, Tooltip } from "@chakra-ui/react";
import { PiHandshakeThin } from "react-icons/pi";
import React from "react";
import { MdNotificationsActive } from "react-icons/md";

export function ItemContent(props) {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <>
      <Flex
        justify="center"
        align="center"
        borderRadius="16px"
        minH={{ base: "60px", md: "70px" }}
        h={{ base: "60px", md: "70px" }}
        minW={{ base: "60px", md: "70px" }}
        w={{ base: "60px", md: "70px" }}
        me="14px"
        bg="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      >
        <Icon
          as={
            props.icon === "notification"
              ? MdNotificationsActive
              : PiHandshakeThin
          }
          color="white"
          w={8}
          h={14}
        />
      </Flex>

      <Tooltip label={props.description} hasArrow placement="top" bg="gray.600">
        <Flex flexDirection="column" width="100%" maxW="calc(100% - 80px)">
          <Text
            mb="5px"
            fontWeight="bold"
            color={textColor}
            fontSize={{ base: "md", md: "md" }}
            noOfLines={1} // Title limited to 1 line
          >
            {props.title || props.info}
          </Text>
          <Flex alignItems="center" width="100%">
            <Text
              fontSize={{ base: "sm", md: "sm" }}
              lineHeight="1.4"
              color={textColor}
              whiteSpace="normal"
              overflowWrap="break-word"
              display="block"
              noOfLines={3} // Limits description to 3 lines
              cursor="pointer" // Indicates that the text is clickable
            >
              {props.description}
            </Text>
          </Flex>
        </Flex>
      </Tooltip>
    </>
  );
}
