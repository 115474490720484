import LoadingScreen from "Pages/Users/LoadingScreen";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedAdminRoute = ({ children }) => {
  const user = useSelector((state) => state.user);

  if (user?.isLoading) {
    return <LoadingScreen />;
  }

  if (!user?.isAuthenticated) return <Navigate to="/auth/sign-in" replace />;

  if (!user?.data?.isAdmin) return <Navigate to="/home" replace />;

  return children;
};

export default ProtectedAdminRoute;
