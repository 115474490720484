// Chakra imports
import {
  Box,
  Card,
  Flex,
  IconButton,
  Collapse,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons"; // Arrow icon from Chakra

const CollapsibleCard = ({
  children,
  title,
  LeftIcon,
  isOpen: defaultIsOpen,
  disabled: defaultDisabled,
  disabledMsg: defaultDisabledMsg,
  onToggle: onToggleCallback,
}) => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure({
    defaultIsOpen: !defaultDisabled && defaultIsOpen,
  }); // Hook to manage the collapse state

  // Synchronize isOpen state with defaultDisabled prop
  useEffect(() => {
    if (defaultDisabled) {
      onClose();
    } else {
      if (defaultIsOpen) {
        onOpen();
      }
    }
  }, [defaultDisabled, defaultIsOpen, onOpen, onClose]);

  return (
    <Card width="100%" mb={{ base: "0px", lg: "20px" }} px={4}>
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        userSelect="none"
        pointerEvents={defaultDisabled ? "none" : "auto"}
        cursor={"pointer"}
        onClick={() => {
          onToggle();
          onToggleCallback?.();
        }}
        opacity={defaultDisabled ? "0.5" : "1.0"}
      >
        {/* Collapsible Arrow Button */}
        <Flex justify={"center"} align={"center"} py={3}>
          <IconButton
            aria-label="Toggle Collapse"
            icon={
              <ChevronDownIcon
                transition="transform 0.2s"
                transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                w={30}
                h={30}
              />
            }
            onClick={onToggle}
            variant="ghost"
            size="md"
          />

          <Text
            align={"right"}
            fontSize={{ base: "xl", md: "4xl" }}
            fontWeight={"bold"}
          >
            {title}
          </Text>
        </Flex>

        {LeftIcon ? LeftIcon : defaultDisabledMsg ? defaultDisabledMsg : ""}
      </Flex>

      {/* Collapsible Content */}
      <Collapse in={isOpen} animateOpacity>
        {!defaultDisabled ? <Box>{children}</Box> : <></>}
      </Collapse>
    </Card>
  );
};

export default CollapsibleCard;
