import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Text,
  useToast,
  Box,
  Checkbox,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  RadioGroup,
  Stack,
  Radio,
  ModalFooter,
  Modal,
  HStack,
  Avatar,
  Center,
  Flex,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import CollapsibleCard from "components/CollapsibleCard";
import { DownloadIcon } from "@chakra-ui/icons";
import { MdAddToPhotos } from "react-icons/md";
import { CircleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";
import { socket } from "../../socket";
import * as XLSX from "xlsx";

const countryData = {
  "+1": { country: "USA/Canada", iso: "US/CA" },
  "+20": { country: "Egypt", iso: "EG" },
  "+44": { country: "UK", iso: "GB" },
  "+966": { country: "Saudi Arabia", iso: "SA" },
  "+971": { country: "UAE", iso: "AE" },
  "+212": { country: "Morocco", iso: "MA" },
  "+33": { country: "France", iso: "FR" },
  "+49": { country: "Germany", iso: "DE" },
  "+81": { country: "Japan", iso: "JP" },
  "+86": { country: "China", iso: "CN" },
  "+91": { country: "India", iso: "IN" },
  "+39": { country: "Italy", iso: "IT" },
  "+34": { country: "Spain", iso: "ES" },
  "+61": { country: "Australia", iso: "AU" },
  "+7": { country: "Russia", iso: "RU" },
  "+55": { country: "Brazil", iso: "BR" },
  "+82": { country: "South Korea", iso: "KR" },
  "+27": { country: "South Africa", iso: "ZA" },
  "+90": { country: "Turkey", iso: "TR" },
  "+60": { country: "Malaysia", iso: "MY" },
  "+62": { country: "Indonesia", iso: "ID" },
  "+234": { country: "Nigeria", iso: "NG" },
  "+353": { country: "Ireland", iso: "IE" },
  "+46": { country: "Sweden", iso: "SE" },
  "+64": { country: "New Zealand", iso: "NZ" },
  "+48": { country: "Poland", iso: "PL" },
  "+31": { country: "Netherlands", iso: "NL" },
  "+45": { country: "Denmark", iso: "DK" },
  "+41": { country: "Switzerland", iso: "CH" },
  // يمكنك إضافة المزيد من الدول إذا أردت
};

const ExportContactsNumber = ({ activeAccountId }) => {
  const user = useSelector((state) => state.user?.data);
  const toast = useToast();

  const [isCardOpen, setIsCardOpen] = useState(false);
  const [exportData, setExportData] = useState("");
  const [shouldDeleteAfterSave, setShouldDeleteAfterSave] = useState(false);
  const [exportDataLoading, setExportDataLoading] = useState(false);

  const [groupLoading, setGroupLoading] = useState(true);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [isAddToSectionModalOpen, setIsAddToSectionModalOpen] = useState(false);
  const [isAddNewSectionModalOpen, setIsAddNewSectionModalOpen] =
    useState(false);
  const [newSectionName, setNewSectionName] = useState("");
  const [sections, setSections] = useState([]);

  const [requestSent, setRequestSent] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("txt");

  // io handelar
  useEffect(() => {
    socket.on("groupsExport", async ({ groupDetails }) => {
      let groupsOptions = groupDetails?.map((group) => ({
        id: group?.id,
        name: group?.name,
        members: group?.membersCount,
        image: group?.image,
      }));
      groupsOptions = groupsOptions.filter((group) => group?.members);

      setGroupOptions(groupsOptions);
      setGroupLoading(false);
    });

    socket.on("membersExport", async (membersData) => {
      if (membersData.status === 200) {
        const uniqMembers = [...new Set(membersData.members)];

        if (!uniqMembers.length) {
          toast({
            title: "مشكله",
            description: "مجموعه بلا اعضاء",
            status: "error",
            duration: 5000,
          });
        }

        const data = uniqMembers.join("\n");
        setExportData(data);
      } else {
        toast({
          title: "فشل",
          description: membersData?.message,
          status: "error",
          duration: 5000,
        });
      }
      setExportDataLoading(false);
      setSelectedGroups([]);
    });

    // Clean up the listener when the component unmounts
    return () => {
      socket.off("groupsExport");
      socket.off("membersExport");
    };
  }, []);

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    const getGroups = async () => {
      try {
        const response = await apiRequest(
          `/whatsapp/getGroups/${activeAccountId}/${localStorage.getItem(
            "token"
          )}`
        );
        if (response.code === 200) {
          setRequestSent(true);
          toast({
            title: "تم",
            description: "جاري تحميل المجموعات من فضلك لا تغلق الصفحه",
            status: "warning",
            duration: 5000,
          });
        } else {
          toast({
            title: "مشكله",
            description: "فشل جلب المجموعات",
            status: "error",
            duration: 5000,
          });
        }
      } catch (error) {
        toast({
          title: "مشكله",
          description: "فشل جلب المجموعات",
          status: "error",
          duration: 5000,
        });
      }
    };

    if (isCardOpen && !requestSent) {
      getGroups();
    }
  }, [isCardOpen, requestSent, activeAccountId]);

  const fetchSections = async () => {
    try {
      const response = await apiRequest("/sections/getSections", "POST");

      if (response.code === 200) {
        setSections(response.sections);
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: "فشل في جلب الأقسام",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleToggleCard = () => {
    setIsCardOpen(!isCardOpen);
  };

  const handleSelectChange = (groupId) => {
    const updatedSelection = selectedGroups.includes(groupId)
      ? selectedGroups.filter((group) => group !== groupId)
      : [...selectedGroups, groupId];

    if (updatedSelection.length <= 5) {
      setSelectedGroups(updatedSelection);
    } else {
      toast({
        title: "تم تجاوز الحد",
        description: "يمكنك اختيار 5 مجموعات كحد أقصى.",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleExport = async () => {
    if (selectedGroups.length > 0) {
      try {
        setExportDataLoading(true);

        const response = await apiRequest(`/whatsapp/exportUsers`, "POST", {
          accountId: activeAccountId,
          groupsId: selectedGroups,
        });
        if (response.code === 200) {
          toast({
            title: "تم",
            description: "جاري تحميل الارقام من فضلك لا تغلق الصفحه",
            status: "warning",
            duration: 5000,
          });
        } else {
          toast({
            title: "مشكله",
            description: "فشل جلب الارقام",
            status: "error",
            duration: 5000,
          });
        }
      } catch (error) {
        toast({
          title: "مشكله",
          description: "فشل جلب المجموعات",
          status: "error",
          duration: 5000,
        });
      } finally {
        setExportDataLoading(false);
      }
    } else {
      toast({
        title: "غير مكتمل",
        description: "الرجاء تحديد مجموعة واحدة على الأقل.",
        status: "warning",
        duration: 3000,
      });
    }
  };

  const handleDownload = () => {
    setIsDownloadModalOpen(true);
  };

  const handleConfirmDownload = () => {
    if (selectedFormat === "txt") {
      const blob = new Blob([exportData], { type: "text/plain;charset=utf-8" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "export.txt";
      link.click();
    } else if (selectedFormat === "excel") {
      const numbersArray = exportData.split("\n");

      // Country codes are arranged by length in descending order to ensure correct matching
      const countryCodes = Object.keys(countryData).sort(
        (a, b) => b.length - a.length
      );

      const dataForExcel = numbersArray.map((number) => {
        let countryCode = null;
        let countryName = "Unknown";
        let countryISO = "Unknown";

        // Search for the matching country code
        for (let code of countryCodes) {
          if (number.startsWith(code)) {
            countryCode = code;
            countryName = countryData[code].country;
            countryISO = countryData[code].iso;
            break;
          }
        }

        return {
          PhoneNumber: number,
          Country: countryName,
          CountryISO: countryISO,
          CountryCode: countryCode || "Unknown",
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate and download an Excel file
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "export.xlsx";
      link.click();
    }
    setIsDownloadModalOpen(false);

    if (shouldDeleteAfterSave) {
      handleReset();
    }

    setShouldDeleteAfterSave(false);
  };

  const handleAddToSection = () => {
    setIsAddToSectionModalOpen(true);
  };

  const handleAddToExistingSection = async (sectionId) => {
    try {
      const response = await apiRequest("/sections/addContacts", "POST", {
        sectionId,
        contacts: exportData.split("\n"),
      });
      if (response.code === 200) {
        toast({
          title: "تم",
          description: "تمت إضافة البيانات إلى القسم بنجاح",
          status: "success",
          duration: 5000,
        });
        setIsAddToSectionModalOpen(false);
      } else {
        toast({
          title: "خطأ",
          description: response.message,
          status: "error",
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: error?.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleCreateNewSection = async () => {
    try {
      const response = await apiRequest("/sections/newSection", "POST", {
        title: newSectionName,
        contacts: [],
      });
      if (response.code === 200) {
        toast({
          title: "تم",
          description: "تمت إضافة القسم بنجاح",
          status: "success",
          duration: 5000,
        });
        fetchSections();
        setIsAddNewSectionModalOpen(false);
        setNewSectionName("");
      } else {
        toast({
          title: "خطأ",
          description: "فشل في إضافة القسم",
          status: "error",
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: "فشل في إضافة القسم",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleReset = () => {
    setSelectedGroups([]);
    setExportData("");
  };

  return (
    <>
      <Card mb={{ base: "0px", lg: "20px" }} px={10}>
        <VStack spacing={4} pt={4} pb={6}>
          <CollapsibleCard
            isOpen={isCardOpen}
            onToggle={handleToggleCard}
            title="مجموعاتك"
          >
            <FormControl py={3}>
              <FormLabel>حدد من مجموعاتك</FormLabel>
              <Box h={270} overflowY="auto" w="100%">
                {groupLoading ? (
                  <Center h="100%">
                    <Flex direction="column" justify="center" align="center">
                      <CircleLoader color="#7e4699" />
                      <Text
                        mt={4}
                        fontSize={"24px"}
                        fontWeight="bold"
                        textShadow="0px 2px 4px rgba(0, 0, 0, 0.3)"
                      >
                        جاري التحميل...
                      </Text>
                    </Flex>
                  </Center>
                ) : !groupOptions.length ? (
                  <Center h="100%">
                    <Text fontSize="lg" fontWeight="bold">
                      لا توجد مجموعات لعرضها
                    </Text>
                  </Center>
                ) : (
                  groupOptions.map((group) => (
                    <HStack
                      key={group.id}
                      spacing={4}
                      py={2}
                      borderBottom="1px solid #eee"
                    >
                      <Checkbox
                        isChecked={selectedGroups.includes(group.id)}
                        onChange={() => handleSelectChange(group.id)}
                      >
                        <HStack spacing={4}>
                          <Avatar
                            src={group.image}
                            boxSize="50px"
                            alt={`${group.name} image`}
                          />
                          <Box>
                            <Text fontWeight="bold">{group.name}</Text>
                            <Text fontSize="sm" color="gray.500">
                              {group.members} عضو
                            </Text>
                          </Box>
                        </HStack>
                      </Checkbox>
                    </HStack>
                  ))
                )}
              </Box>
              <Text mt={2} color="gray.500">
                لقد حددت {selectedGroups.length} من اصل 5 مجموعات
              </Text>
            </FormControl>
          </CollapsibleCard>

          <Button
            isLoading={exportDataLoading}
            loadingText="سحب الارقام"
            colorScheme="blue"
            onClick={handleExport}
          >
            سحب الارقام
          </Button>
          <Textarea
            placeholder="ستظهر البيانات المصدرة هنا..."
            value={exportData}
            isReadOnly
            mt={4}
            h={200}
            resize={"none"}
          />

          {exportData !== "" ? (
            <Flex
              width={"100%"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text fontWeight={"bold"}>
                عدد الارقام:{" "}
                <Text as={"span"}>{exportData?.split("\n")?.length || 0}</Text>
              </Text>

              <Button fontSize={"sm"} colorScheme="red" onClick={handleReset}>
                إعادة تعيين
              </Button>
            </Flex>
          ) : (
            ""
          )}

          <Flex gap={5}>
            <Button
              colorScheme="blue"
              onClick={handleAddToSection}
              isDisabled={!exportData}
              leftIcon={<MdAddToPhotos />}
            >
              اضف الي قسم
            </Button>

            <Button
              colorScheme="green"
              onClick={handleDownload}
              isDisabled={!exportData}
              leftIcon={<DownloadIcon />}
            >
              تحميل
            </Button>
          </Flex>
        </VStack>
      </Card>

      {/* Save Model */}
      <Modal
        isCentered
        isOpen={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader ms={6}>اختر صيغة الملف</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={setSelectedFormat} value={selectedFormat}>
              <Stack direction="column">
                <Radio value="txt">TXT.</Radio>
                <Radio value="excel">Excel.</Radio>
              </Stack>
            </RadioGroup>

            <Checkbox
              mt={4}
              isChecked={shouldDeleteAfterSave}
              onChange={(e) => setShouldDeleteAfterSave(e.target.checked)}
            >
              حذف القائمة بعد الحفظ
            </Checkbox>
          </ModalBody>

          <ModalFooter gap={3}>
            <Button colorScheme="green" mr={3} onClick={handleConfirmDownload}>
              تنزيل
            </Button>
            <Button
              colorScheme="red"
              onClick={() => setIsDownloadModalOpen(false)}
            >
              الغاء
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add To Section Model */}
      <Modal
        isCentered
        isOpen={isAddToSectionModalOpen}
        onClose={() => setIsAddToSectionModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader ms={6}>اختر القسم</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={2} spacing={2}>
              {sections.map((section) => (
                <Button
                  key={section._id}
                  variant="outline"
                  onClick={() => handleAddToExistingSection(section._id)}
                >
                  {section.title}
                </Button>
              ))}

              <Button
                variant="ghost"
                gridColumn="span 2"
                colorScheme="blue"
                onClick={() => setIsAddNewSectionModalOpen(true)}
              >
                + أضف قسم
              </Button>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter m={"auto"}>
            <Button
              colorScheme="red"
              onClick={() => setIsAddToSectionModalOpen(false)}
            >
              الغاء
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* New Section Model */}
      <Modal
        isCentered
        isOpen={isAddNewSectionModalOpen}
        onClose={() => setIsAddNewSectionModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader ms={6}>أضف قسم جديد</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>اسم القسم</FormLabel>
              <Input
                value={newSectionName}
                onChange={(e) => setNewSectionName(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter gap={3}>
            <Button colorScheme="blue" onClick={handleCreateNewSection}>
              أضف
            </Button>
            <Button
              colorScheme="red"
              onClick={() => setIsAddNewSectionModalOpen(false)}
            >
              الغاء
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExportContactsNumber;
