import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const ColumnChart = ({ chartData, chartOptions }) => {
  // Initialize state with chart data and options from props
  const [chartState, setChartState] = useState({
    data: chartData || [],
    options: {
      ...chartOptions,
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#834c9d"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 0.3,
          stops: [0, 100],
        },
      },
      colors: ["#834c9d"],
    },
  });

  useEffect(() => {
    // Update state if chartData or chartOptions props have changed
    setChartState({
      data: chartData,
      options: {
        ...chartOptions,
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: ["#834c9d"],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.3,
            stops: [0, 100],
          },
        },
        colors: ["#834c9d"],
      },
    });
  }, [chartData, chartOptions]);

  // Render the chart component with the current options and data
  return (
    <Chart
      options={chartState.options}
      series={chartState.data}
      type="bar"
      width="100%"
      height="100%"
    />
  );
};

export default ColumnChart;
