import React, { useEffect, useState } from "react";
import loginImage from "../../../assets/img/auth/log_out.png";
import otpImage from "../../../assets/img/auth/sign_in.png";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  Image,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import arabicCountries from "react-phone-input-2/lang/ar.json";
import { useNavigate } from "react-router-dom";
import apiRequest from "utils/api";

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");

  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  // إرسال OTP
  const sendOtp = async () => {
    setError("");
    setIsSending(true);
    try {
      const response = await apiRequest("/auth/login", "POST", {
        phone: `+${phone}`,
      });
      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: response.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setStep(2);
      } else {
        setError(response.message);
        document.getElementById("phoneNumberInputLogin")?.focus();
      }
    } catch (error) {
      document.getElementById("phoneNumberInputLogin")?.focus();
      setError(error?.response?.data?.message || error?.message);
    } finally {
      setIsSending(false);
    }
  };

  const resendCode = () => {
    if (isResendDisabled) return;

    sendOtp();
    setIsResendDisabled(true);
  };

  // التحقق من OTP
  const verifyOtp = async () => {
    const otpValue = otp.join("");
    if (otpValue.length === 4) {
      setIsVerifying(true);
      try {
        const response = await apiRequest("/auth/checkotp", "POST", {
          phone: `+${phone}`,
          otp: otpValue,
          otpType: "login",
        });
        if (response.code === 200) {
          toast({
            title: "تم التحقق بنجاح",
            description: "تم تسجيل الدخول بنجاح",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          localStorage.setItem("token", response?.user?.token);
          window.location.reload();
        } else {
          setOtp(new Array(4).fill(""));
          document.getElementById(`otp-0`).focus();
          setError(response.message);
        }
      } catch (error) {
        setOtp(new Array(4).fill(""));
        document.getElementById(`otp-0`).focus();
        setError(error?.response?.data?.message || error?.message);
      } finally {
        setIsVerifying(false);
      }
    } else {
      setOtp(new Array(4).fill(""));
      document.getElementById(`otp-0`).focus();
      setError("يرجى إدخال الرمز بشكل صحيح.");
    }
  };

  // Handle OTP input in multiple fields
  const handleOtpChange = (value, index) => {
    if (isNaN(value) || value === " ") return; // Check if the input is a number

    if (!value && index) {
      document.getElementById(`otp-${index - 1}`).focus();
    }

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move to the next input field
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const backStep = () => {
    setStep(1);
    setPhone("");
    setError("");
  };

  useEffect(() => {
    if (otp.join("").length >= 4) {
      verifyOtp();
    }
  }, [otp]);

  return (
    <Box py={10}>
      <Heading
        display={{ base: "block", md: "none" }}
        as="h2"
        size="lg"
        textAlign="center"
      >
        {step === 1 ? "تسجيل الدخول" : "تأكيد الرقم"}
      </Heading>
      <Text
        display={{ base: "block", md: "none" }}
        align={"center"}
        color={"#6c757d"}
        fontSize={14}
        mt={"15px !important"}
      >
        {step === 1
          ? "الرجاء إدخال رقم الهاتف الخاص بك لتسجيل الدخول"
          : `الرجاء إدخال الرمز المكون من 4 أرقام الذي أرسلناه إليك`}
      </Text>

      <Flex
        minH="100vh"
        align={{ base: "start", md: "center" }}
        justify="center"
        p={6}
      >
        <Flex
          flexDirection={{ base: "column-reverse", md: "row" }}
          w={{ base: "100%", md: "70%", lg: "60%" }}
          flexWrap={"wrap"}
        >
          <VStack
            spacing={{ base: 0, md: 6 }}
            w={{ base: "100%", md: "50%" }}
            p={{ base: 1, md: 8 }}
            align="stretch"
            justify="center"
          >
            <Heading
              display={{ base: "none", md: "block" }}
              as="h2"
              size="lg"
              textAlign="center"
            >
              {step === 1 ? "تسجيل الدخول" : "تأكيد الرقم"}
            </Heading>
            <Text
              display={{ base: "none", md: "block" }}
              align={"center"}
              color={"#6c757d"}
              fontSize={14}
              mt={"15px !important"}
            >
              {step === 1
                ? "الرجاء إدخال رقم الهاتف الخاص بك لتسجيل الدخول"
                : `الرجاء إدخال الرمز المكون من 4 أرقام الذي أرسلناه إليك`}
            </Text>
            {step === 2 && (
              <Flex
                mt={"13px !important"}
                alignItems={"center"}
                justifyContent={"center"}
                color={"#6c757d"}
                gap={2}
                fontSize={15}
              >
                <Text
                  onClick={backStep}
                  color={"#25B697"}
                  fontWeight={"bold"}
                  as={"button"}
                >
                  تعديل
                </Text>
                <Text>{phone.startsWith("+") ? phone : `+${phone}`}</Text>
              </Flex>
            )}

            {step === 1 ? (
              <VStack spacing={4}>
                <PhoneInput
                  inputProps={{ id: "phoneNumberInputLogin" }}
                  onEnterKeyPress={sendOtp}
                  country={"sa"}
                  value={phone}
                  placeholder="+xx xxx xxx xxx"
                  localization={arabicCountries}
                  onChange={setPhone}
                  inputStyle={{ width: "100%", height: "50px" }}
                  buttonStyle={{ backgroundColor: "#EAEAEA" }}
                />
                {error && (
                  <Text color="red.500" fontSize="sm">
                    {error}
                  </Text>
                )}
                <Flex
                  mt={"16px !important"}
                  w={"100%"}
                  color={"#6c757d"}
                  fontSize={14}
                >
                  <Text
                    onClick={() => navigate("/auth/sign-up")}
                    color={"#25B697"}
                    fontWeight={"bold"}
                    as={"button"}
                    me={1}
                  >
                    انشاء حساب
                  </Text>
                  <Text>ليس لديك حساب بعد ؟</Text>
                </Flex>
                <Button
                  mt={"25px !important"}
                  colorScheme="teal"
                  size="lg"
                  py={"28px"}
                  bg={"#7E4699"}
                  _hover={{ bg: "#533064" }}
                  width="full"
                  onClick={sendOtp}
                  isLoading={isSending}
                  loadingText="جاري التحقق"
                >
                  تسجيل الدخول
                </Button>
              </VStack>
            ) : (
              <VStack spacing={4}>
                <HStack justify="center">
                  {otp.map((data, index) => (
                    <Input
                      autoComplete="off"
                      key={index}
                      placeholder="X"
                      id={`otp-${index}`}
                      type="text"
                      maxLength="1"
                      size="lg"
                      fontSize={"25px"}
                      textAlign="center"
                      value={data}
                      onChange={(e) => handleOtpChange(e.target.value, index)}
                      width={"4rem"}
                      height={"4rem"}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace" && index) {
                          document.getElementById(`otp-${index - 1}`).focus();
                        }
                      }}
                    />
                  ))}
                </HStack>
                {error && (
                  <Text color="red.500" fontSize="sm">
                    {error}
                  </Text>
                )}
                <Button
                  colorScheme="teal"
                  size="lg"
                  width="full"
                  py={"28px"}
                  bg={"#7E4699"}
                  _hover={{ bg: "#533064" }}
                  onClick={verifyOtp}
                  isLoading={isVerifying}
                  loadingText="جاري التحقق"
                >
                  تحقق
                </Button>

                {!isResendDisabled && (
                  <Text
                    color="#25B697"
                    fontSize="sm"
                    mt="2"
                    fontWeight={"bold"}
                    cursor="pointer"
                    onClick={resendCode}
                  >
                    إعادة إرسال كود التحقق
                  </Text>
                )}
              </VStack>
            )}
          </VStack>

          <Box
            m={"auto"}
            w={{ base: "80%", md: "50%" }}
            border={{ md: "2px solid #EAEAEA" }}
            borderRadius={"35px"}
            color="white"
            px={{ base: 1, md: 8 }}
            py={{ base: "20px", md: "70px" }}
          >
            <Flex align="center" justify="center">
              <Image
                src={step === 1 ? loginImage : otpImage}
                alt="Illustration"
                boxSize="80%"
              />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Login;
