import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";

const GeneralSettings = () => {
  const toast = useToast();

  const [formData, setFormData] = useState({
    facebook: "",
    twitter: "",
    whatsapp: "",
    email: "",
    summary: "",
  });

  const [loadingData, setLoadingData] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  // جلب بيانات الهبوط الحالية
  const fetchLandingData = async () => {
    setLoadingData(true);
    try {
      const response = await apiRequest("/publicData/getLandingData", "GET");
      if (response.code === 200) {
        setFormData({
          facebook: response.socialAccounts?.facebook || "",
          twitter: response.socialAccounts?.twitter || "",
          whatsapp: response.socialAccounts?.whatsapp || "",
          email: response.socialAccounts?.email || "",
          summary: response.summary || "",
        });
      } else {
        toast({
          title: "خطأ",
          description: response.message || "فشل في جلب بيانات الهبوط.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: error.message || "حدث خطأ أثناء جلب بيانات الهبوط.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLandingData();
  }, []);

  // إدارة تغييرات النموذج
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // إدارة إرسال النموذج
  const handleSubmit = async (e) => {
    e.preventDefault();

    // التحقق من صحة الحقول المطلوبة
    if (!formData.summary.trim()) {
      toast({
        title: "خطأ في الإدخال",
        description: "يرجى ملء ملخص صفحة الهبوط.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!formData.email.trim()) {
      toast({
        title: "خطأ في الإدخال",
        description: "يرجى ملء عنوان البريد الإلكتروني.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // إضافة تحقق إضافي إذا لزم الأمر (مثل صحة الروابط وأرقام الهاتف)

    // تجهيز البيانات المرسلة
    const payload = {
      token: localStorage.getItem("token"),
      summary: formData.summary,
      socialAccounts: {
        facebook: formData.facebook,
        twitter: formData.twitter,
        whatsapp: formData.whatsapp,
        email: formData.email,
      },
    };

    try {
      setSubmitLoading(true);
      const response = await apiRequest(
        "/admin/publicData/updateLandingData",
        "POST",
        payload
      );

      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: response.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // إعادة جلب البيانات للتأكد من التحديث
        fetchLandingData();
      } else {
        toast({
          title: "فشل التحديث",
          description: response.message || "حدث خطأ أثناء تحديث بيانات الهبوط.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: error.message || "حدث خطأ أثناء تحديث بيانات الهبوط.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Box
      pt={{ base: "130px !important", md: "120px" }}
      p={5}
      maxW="800px"
      mx="auto"
    >
      {loadingData ? (
        <Box textAlign="center" py={10}>
          <Spinner size="xl" />
        </Box>
      ) : (
        <Box bg="white" boxShadow="md" borderRadius="md" p={6}>
          <form onSubmit={handleSubmit}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
              {/* حساب فيسبوك */}
              <FormControl>
                <FormLabel>رابط فيسبوك</FormLabel>
                <Input
                  type="url"
                  placeholder="https://facebook.com/yourpage"
                  name="facebook"
                  value={formData.facebook}
                  onChange={handleChange}
                />
              </FormControl>

              {/* حساب تويتر */}
              <FormControl>
                <FormLabel>رابط تويتر</FormLabel>
                <Input
                  type="url"
                  placeholder="https://twitter.com/yourpage"
                  name="twitter"
                  value={formData.twitter}
                  onChange={handleChange}
                />
              </FormControl>

              {/* رقم واتساب */}
              <FormControl>
                <FormLabel>رقم واتساب</FormLabel>
                <Input
                  type="tel"
                  placeholder="+1234567890"
                  name="whatsapp"
                  value={formData.whatsapp}
                  onChange={handleChange}
                />
              </FormControl>

              {/* البريد الإلكتروني */}
              <FormControl isRequired>
                <FormLabel>البريد الإلكتروني</FormLabel>
                <Input
                  type="email"
                  placeholder="your-email@example.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormControl>

              {/* ملخص صفحة الهبوط (آخر حقل) */}
              <FormControl isRequired gridColumn={{ md: "span 2" }}>
                <FormLabel>ملخص صفحة الهبوط</FormLabel>
                <Textarea
                  placeholder="أدخل ملخص صفحة الهبوط"
                  name="summary"
                  value={formData.summary}
                  onChange={handleChange}
                  rows={6}
                />
              </FormControl>
            </SimpleGrid>

            {/* زر الإرسال */}
            <Button
              type="submit"
              colorScheme="teal"
              mt={6}
              isLoading={submitLoading}
              loadingText="جاري التحديث..."
              w="full"
            >
              تحديث الإعدادات
            </Button>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default GeneralSettings;
