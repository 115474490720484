import customAxios from "./axiosRequest";

const apiRequest = async (route, method = "GET", data = null) => {
  try {
    const token = localStorage.getItem("token");

    if (data instanceof FormData) {
      data.append("token", token);
    } else {
      data = { token, ...data };
    }

    const response = await customAxios({
      url: route,
      method,
      data,
    });
    return response.data;
  } catch (error) {
    console.error("API request error:", error);
    throw error; // Rethrow to handle it in the calling code
  }
};

export default apiRequest;
