import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";

// Custom icons
import React from "react";

export default function Default(props) {
  const { startContent, endContent, name, details, growth, value, title } =
    props;

  return (
    <Card
      boxShadow={"none !important"}
      border="1px solid #eaeaea"
      borderRadius="35px"
      padding="35px"
      title={title || ""}
    >
      <Flex
        my="auto"
        h="100%"
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}
      >
        {startContent}

        <Stat my="auto" ms={startContent ? "18px" : "0px"}>
          <StatLabel
            lineHeight="100%"
            fontWeight={"bold"}
            color={"#000"}
            fontSize={{
              base: "lg",
            }}
          >
            {name}{" "}
            {details && (
              <Text as="span" color="#7a7a7a" fontSize="13px">
                {details}
              </Text>
            )}
          </StatLabel>
          <StatNumber
            color={"#6c757d"}
            fontWeight={"bold"}
            fontSize={{
              base: "2xl",
            }}
          >
            {value}
          </StatNumber>
          {growth ? (
            <Flex align="center">
              <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                {growth}
              </Text>
              <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                since last month
              </Text>
            </Flex>
          ) : null}
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
