import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import apiRequest from "utils/api";

const Notification = () => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    title: "",
    body: "",
    all: true,
    packageId: "",
  });

  const [packages, setPackages] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Fetch packages for the Package ID select field
  const fetchPackages = async () => {
    setLoadingPackages(true);
    try {
      const response = await apiRequest("/package/getPackages", "GET");
      if (response.code === 200) {
        setPackages(response.packages);
      } else {
        toast({
          title: "خطأ",
          description: response.message || "فشل في جلب الباقات",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: error.message || "حدث خطأ أثناء جلب الباقات",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingPackages(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
        // Reset packageId if 'all' is checked
        ...(name === "all" && checked ? { packageId: "" } : {}),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.title.trim() || !formData.body.trim()) {
      toast({
        title: "خطأ في الإدخال",
        description: "يرجى ملء عنوان الرسالة ونصها.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // If 'all' is false, at least one of packageId should be provided
    if (!formData.all && !formData.packageId.trim()) {
      toast({
        title: "خطأ في الإدخال",
        description: "يرجى تحديد باقة لإرسال الإشعار إليها، أو تحديد 'الكل'.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Prepare payload
    const payload = {
      title: formData.title,
      body: formData.body,
    };

    if (!formData.all) {
      if (formData.packageId.trim())
        payload.packageId = formData.packageId.trim();
    } else {
      payload.all = "true";
    }

    try {
      setSubmitLoading(true);
      const response = await apiRequest(
        "/admin/notification/sendNotification",
        "POST",
        payload
      );

      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: `تم إرسال الإشعار الي ${response.count} من المستخدمين بنجاح.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Reset form
        setFormData({
          title: "",
          body: "",
          all: true,
          packageId: "",
        });
      } else {
        toast({
          title: "فشل الإرسال",
          description: response.message || "حدث خطأ أثناء إرسال الإشعار.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: error.message || "حدث خطأ أثناء إرسال الإشعار.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Box
      pt={{ base: "130px !important", md: "120px" }}
      p={5}
      bg="white"
      boxShadow="md"
      borderRadius="md"
      maxW="800px"
      mx="auto"
    >
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={{ base: 1 }} spacing={5}>
          {/* Title */}
          <FormControl isRequired>
            <FormLabel>عنوان الإشعار</FormLabel>
            <Input
              placeholder="أدخل عنوان الإشعار"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
          </FormControl>

          {/* Body */}
          <FormControl isRequired>
            <FormLabel>نص الإشعار</FormLabel>
            <Textarea
              placeholder="أدخل نص الإشعار"
              name="body"
              value={formData.body}
              onChange={handleChange}
              rows={6}
            />
          </FormControl>

          {/* All Users Checkbox */}
          <FormControl>
            <Checkbox
              name="all"
              isChecked={formData.all}
              onChange={handleChange}
            >
              إرسال إلى جميع المستخدمين
            </Checkbox>
          </FormControl>

          {/* Package ID Select */}
          <FormControl isDisabled={formData.all}>
            <FormLabel>حدد الباقة (اختياري)</FormLabel>
            {loadingPackages ? (
              <Spinner />
            ) : (
              <Select
                placeholder="اختر باقة"
                name="packageId"
                value={formData.packageId}
                onChange={handleChange}
              >
                {packages.map((packageItem) => (
                  <option key={packageItem._id} value={packageItem._id}>
                    {packageItem.title}
                  </option>
                ))}
              </Select>
            )}
          </FormControl>

          {/* Submit Button */}
          <Button
            type="submit"
            colorScheme="teal"
            background={"#7e4699"}
            _hover={{ background: "#7e4699" }}
            _focus={{ background: "#7e4699" }}
            isLoading={submitLoading}
            loadingText="جاري الإرسال..."
          >
            إرسال الإشعار
          </Button>
        </SimpleGrid>
      </form>
    </Box>
  );
};

export default Notification;
