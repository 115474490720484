import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Text,
  useToast,
  Box,
  Checkbox,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  RadioGroup,
  Stack,
  Radio,
  ModalFooter,
  Modal,
  HStack,
  Avatar,
  Center,
  Flex,
} from "@chakra-ui/react";
import CollapsibleCard from "components/CollapsibleCard";
import { DownloadIcon } from "@chakra-ui/icons";
import { CircleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";
import { socket } from "../../socket";
import * as XLSX from "xlsx";

const ExportUrlsComponent = ({ activeAccountId }) => {
  const user = useSelector((state) => state.user?.data);
  const toast = useToast();

  const [isCardOpen, setIsCardOpen] = useState(false);
  const [exportData, setExportData] = useState("");

  const [requestSent, setRequestSent] = useState(false);
  const [shouldDeleteAfterSave, setShouldDeleteAfterSave] = useState(false);
  const [exportDataLoading, setExportDataLoading] = useState(false);

  const [groupLoading, setGroupLoading] = useState(true);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("txt");

  // io handelar
  useEffect(() => {
    socket.on("groupsExport", async ({ groupDetails }) => {
      let groupsOptions = groupDetails?.map((group) => ({
        id: group?.id,
        name: group?.name,
        members: group?.membersCount,
        image: group?.image,
      }));
      groupsOptions = groupsOptions.filter((group) => group?.members);

      setGroupOptions(groupsOptions);
      setGroupLoading(false);
    });

    socket.on("linksExport", async (urlData) => {
      if (urlData.status === 200) {
        const uniqUrls = [...new Set(urlData?.resultURLs)];

        if (!uniqUrls.length) {
          toast({
            title: "تم",
            description: "لا توجد روابط في رسائل المجموعات مؤخرا",
            status: "success",
            duration: 5000,
          });
        }

        const data = uniqUrls.join("\n");
        setExportData(data);
      } else {
        toast({
          title: "فشل",
          description: urlData?.message,
          status: "error",
          duration: 5000,
        });
      }
      setExportDataLoading(false);
      setSelectedGroups([]);
    });

    // Clean up the listener when the component unmounts
    return () => {
      socket.off("groupsExport");
      socket.off("linksExport");
    };
  }, []);

  useEffect(() => {
    const getGroups = async () => {
      try {
        const response = await apiRequest(
          `/whatsapp/getGroups/${activeAccountId}/${localStorage.getItem(
            "token"
          )}`
        );
        if (response.code === 200) {
          setRequestSent(true);
          toast({
            title: "تم",
            description: "جاري تحميل المجموعات من فضلك لا تغلق الصفحه",
            status: "warning",
            duration: 5000,
          });
        } else {
          toast({
            title: "مشكله",
            description: "فشل جلب المجموعات",
            status: "error",
            duration: 5000,
          });
        }
      } catch (error) {
        toast({
          title: "مشكله",
          description: "فشل جلب المجموعات",
          status: "error",
          duration: 5000,
        });
      }
    };

    if (isCardOpen && !requestSent) {
      getGroups();
    }
  }, [isCardOpen, requestSent, activeAccountId]);

  const handleToggleCard = () => {
    setIsCardOpen(!isCardOpen);
  };

  const handleSelectChange = (groupId) => {
    const updatedSelection = selectedGroups.includes(groupId)
      ? selectedGroups.filter((group) => group !== groupId)
      : [...selectedGroups, groupId];

    if (updatedSelection.length <= 5) {
      setSelectedGroups(updatedSelection);
    } else {
      toast({
        title: "تم تجاوز الحد",
        description: "يمكنك اختيار 5 مجموعات كحد أقصى.",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleExport = async () => {
    if (selectedGroups.length > 0) {
      try {
        setExportDataLoading(true);

        const response = await apiRequest(`/whatsapp/exportUrls`, "POST", {
          accountId: activeAccountId,
          groupsId: selectedGroups,
        });

        if (response.code === 200) {
          toast({
            title: "تم",
            description: "جاري تحميل الروابط من فضلك لا تغلق الصفحه",
            status: "warning",
            duration: 5000,
          });
        } else {
          toast({
            title: "مشكله",
            description: response.message,
            status: "error",
            duration: 5000,
          });
        }
      } catch (error) {
        toast({
          title: "مشكله",
          description: error?.response.data.message || "فشل جلب الروابط",
          status: "error",
          duration: 5000,
        });
      } finally {
        setExportDataLoading(false);
      }
    } else {
      toast({
        title: "غير مكتمل",
        description: "الرجاء تحديد مجموعة واحدة على الأقل.",
        status: "warning",
        duration: 3000,
      });
    }
  };

  const handleDownload = () => {
    setIsDownloadModalOpen(true);
  };

  const handleConfirmDownload = () => {
    if (selectedFormat === "txt") {
      const blob = new Blob([exportData], { type: "text/plain;charset=utf-8" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "export.txt";
      link.click();
    } else if (selectedFormat === "excel") {
      const urlsArray = exportData.split("\n");

      // Create data for Excel with host name extraction
      const dataForExcel = urlsArray.map((url) => {
        try {
          const parsedUrl = new URL(url);
          let hostname = parsedUrl.hostname;

          // Remove prefix 'www.' If found
          if (hostname.startsWith("www.")) {
            hostname = hostname.substring(4);
          }

          // Split the hostname and get the main domain
          const hostnameParts = hostname.split(".");
          let domain = hostnameParts[0];

          if (hostnameParts.length >= 2) {
            domain = hostnameParts[hostnameParts.length - 2];
          }

          return {
            link: url,
            website: domain.charAt(0).toUpperCase() + domain.slice(1),
          };
        } catch (e) {
          // If an error occurs in URL parsing
          return { hostname: "Invalid URL" };
        }
      });

      // Create an Excel worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate and download an Excel file
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "export.xlsx";
      link.click();
    }
    setIsDownloadModalOpen(false);

    if (shouldDeleteAfterSave) {
      handleReset();
    }

    setShouldDeleteAfterSave(false);
  };

  const handleReset = () => {
    setSelectedGroups([]);
    setExportData("");
  };

  return (
    <>
      <Card mb={{ base: "0px", lg: "20px" }} px={10}>
        <VStack spacing={4} pt={4} pb={6}>
          <CollapsibleCard
            isOpen={isCardOpen}
            onToggle={handleToggleCard}
            title="مجموعاتك"
          >
            <FormControl py={3}>
              <FormLabel>حدد من مجموعاتك</FormLabel>
              <Box h={270} overflowY="auto" w="100%">
                {groupLoading ? (
                  <Center h="100%">
                    <Flex direction="column" justify="center" align="center">
                      <CircleLoader color="#7e4699" />
                      <Text
                        mt={4}
                        fontSize={"24px"}
                        fontWeight="bold"
                        textShadow="0px 2px 4px rgba(0, 0, 0, 0.3)"
                      >
                        جاري التحميل...
                      </Text>
                    </Flex>
                  </Center>
                ) : !groupOptions.length ? (
                  <Center h="100%">
                    <Text fontSize="lg" fontWeight="bold">
                      لا توجد مجموعات لعرضها
                    </Text>
                  </Center>
                ) : (
                  groupOptions.map((group) => (
                    <HStack
                      key={group.id}
                      spacing={4}
                      py={2}
                      borderBottom="1px solid #eee"
                    >
                      <Checkbox
                        isChecked={selectedGroups.includes(group.id)}
                        onChange={() => handleSelectChange(group.id)}
                      >
                        <HStack spacing={4}>
                          <Avatar
                            src={group.image}
                            boxSize="50px"
                            alt={`${group.name} image`}
                          />
                          <Box>
                            <Text fontWeight="bold">{group.name}</Text>
                            <Text fontSize="sm" color="gray.500">
                              {group.members} عضو
                            </Text>
                          </Box>
                        </HStack>
                      </Checkbox>
                    </HStack>
                  ))
                )}
              </Box>
              <Text mt={2} color="gray.500">
                لقد حددت {selectedGroups.length} من اصل 5 مجموعات
              </Text>
            </FormControl>
          </CollapsibleCard>

          <Button
            isLoading={exportDataLoading}
            loadingText="سحب الروابط"
            colorScheme="blue"
            onClick={handleExport}
          >
            سحب الروابط
          </Button>
          <Textarea
            placeholder="ستظهر البيانات المصدرة هنا..."
            value={exportData}
            isReadOnly
            mt={4}
            h={200}
            resize={"none"}
          />

          {exportData !== "" ? (
            <Flex
              width={"100%"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text fontWeight={"bold"}>
                عدد الروابط:{" "}
                <Text as={"span"}>{exportData?.split("\n")?.length || 0}</Text>
              </Text>

              <Button fontSize={"sm"} colorScheme="red" onClick={handleReset}>
                إعادة تعيين
              </Button>
            </Flex>
          ) : (
            ""
          )}

          <Button
            colorScheme="green"
            onClick={handleDownload}
            isDisabled={!exportData}
            leftIcon={<DownloadIcon />}
          >
            تحميل
          </Button>
        </VStack>
      </Card>

      {/* Save Model */}
      <Modal
        isCentered
        isOpen={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader ms={6}>اختر صيغة الملف</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={setSelectedFormat} value={selectedFormat}>
              <Stack direction="column">
                <Radio value="txt">TXT.</Radio>
                <Radio value="excel">Excel.</Radio>
              </Stack>
            </RadioGroup>

            <Checkbox
              mt={4}
              isChecked={shouldDeleteAfterSave}
              onChange={(e) => setShouldDeleteAfterSave(e.target.checked)}
            >
              حذف القائمة بعد الحفظ
            </Checkbox>
          </ModalBody>

          <ModalFooter gap={3}>
            <Button colorScheme="green" mr={3} onClick={handleConfirmDownload}>
              تنزيل
            </Button>
            <Button
              colorScheme="red"
              onClick={() => setIsDownloadModalOpen(false)}
            >
              الغاء
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExportUrlsComponent;
