import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdLock,
  MdOutlineNotificationAdd,
  MdOutlineSettings,
} from "react-icons/md";
import { LuMessagesSquare, LuUsers2 } from "react-icons/lu";
import { IoIosLink } from "react-icons/io";
import { IoLibraryOutline, IoHomeOutline } from "react-icons/io5";
import { TbPackages, TbTools } from "react-icons/tb";
import { PiUsersThreeBold } from "react-icons/pi";

// User Pages
import Home from "./Pages/Users/Home";
import Messages from "./Pages/Users/Messages";
import Sections from "./Pages/Users/Sections";
import ExportContacts from "./Pages/Users/ExportContacts";
import ExportUrls from "./Pages/Users/ExportUrls";
import Profile from "./Pages/Users/Profile";
import WhatsappAccounts from "./Pages/Users/WhatsappAccounts";

// Admin Pages
import AdminHome from "./Pages/Admin/Home";
import AdminSections from "./Pages/Admin/Sections";
import AdminNotification from "./Pages/Admin/Notification";
import AdminUsers from "./Pages/Admin/Users";
import AdminPackages from "./Pages/Admin/Packages";
import AdminCoupons from "./Pages/Admin/Coupons";
import AdminGeneralSettings from "./Pages/Admin/GeneralSettings";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUp from "views/auth/signup";
import { RiCoupon3Line } from "react-icons/ri";

const routes = [
  {
    name: "الصفحة الرئيسية",
    layout: "/rtl",
    path: "/home",
    icon: (
      <Icon as={IoHomeOutline} width="27px" height="27px" color="inherit" />
    ),
    component: <Home />,
  },
  {
    name: "حسابات الواتساب",
    layout: "/rtl",
    path: "/whatsappAccounts",
    icon: (
      <Icon as={PiUsersThreeBold} width="27px" height="27px" color="inherit" />
    ),
    component: <WhatsappAccounts />,
  },
  {
    name: "الرسائل",
    layout: "/rtl",
    path: "/messages",
    icon: (
      <Icon as={LuMessagesSquare} width="27px" height="27px" color="inherit" />
    ),
    component: <Messages />,
  },
  {
    name: "سحب الارقام",
    layout: "/rtl",
    path: "/exportContacts",
    icon: <Icon as={TbTools} width="27px" height="27px" color="inherit" />,
    component: <ExportContacts />,
  },
  {
    name: "سحب الروابط",
    layout: "/rtl",
    path: "/exportUrls",
    icon: <Icon as={IoIosLink} width="27px" height="27px" color="inherit" />,
    component: <ExportUrls />,
  },
  {
    name: "الاقسام",
    layout: "/rtl",
    path: "/sections",
    icon: (
      <Icon as={IoLibraryOutline} width="27px" height="27px" color="inherit" />
    ),
    component: <Sections />,
  },
  {
    name: "الملف الشخصي",
    layout: "/rtl",
    path: "/profile",
    icon: <Icon as={MdLock} width="27px" height="27px" color="inherit" />,
    component: <Profile />,
    hiddenFromToolbar: true,
  },

  {
    name: "تسجيل الدخول",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="27px" height="27px" color="inherit" />,
    component: <SignInCentered />,
  },
  {
    name: "تسجيل حساب",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="27px" height="27px" color="inherit" />,
    component: <SignUp />,
    hiddenFromToolbar: true,
  },

  // Admin Routes
  {
    name: "الصفحة الرئيسية",
    layout: "/rtl",
    path: "/admin",
    icon: (
      <Icon as={IoHomeOutline} width="27px" height="27px" color="inherit" />
    ),
    component: <AdminHome />,
    adminRoute: true,
  },
  {
    name: "الاقسام",
    layout: "/rtl",
    path: "/admin/section",
    icon: (
      <Icon as={IoLibraryOutline} width="27px" height="27px" color="inherit" />
    ),
    component: <AdminSections />,
    adminRoute: true,
  },
  {
    name: "الإشعارات",
    layout: "/rtl",
    path: "/admin/notification",
    icon: (
      <Icon
        as={MdOutlineNotificationAdd}
        width="27px"
        height="27px"
        color="inherit"
      />
    ),
    component: <AdminNotification />,
    adminRoute: true,
  },
  {
    name: "المستخدمين",
    layout: "/rtl",
    path: "/admin/users",
    icon: <Icon as={LuUsers2} width="27px" height="27px" color="inherit" />,
    component: <AdminUsers />,
    adminRoute: true,
  },
  {
    name: "الباقات",
    layout: "/rtl",
    path: "/admin/packages",
    icon: <Icon as={TbPackages} width="27px" height="27px" color="inherit" />,
    component: <AdminPackages />,
    adminRoute: true,
  },
  {
    name: "الكوبونات",
    layout: "/rtl",
    path: "/admin/coupons",
    icon: (
      <Icon as={RiCoupon3Line} width="27px" height="27px" color="inherit" />
    ),
    component: <AdminCoupons />,
    adminRoute: true,
  },
  {
    name: "الإعدادات العامه",
    layout: "/rtl",
    path: "/admin/generalSettings",
    icon: (
      <Icon as={MdOutlineSettings} width="27px" height="27px" color="inherit" />
    ),
    component: <AdminGeneralSettings />,
    adminRoute: true,
  },

  // Logout Route
  {
    name: "تسجيل الخروج",
    layout: "/rtl",
    path: "/logout",
    icon: <Icon as={MdLock} width="27px" height="27px" color="inherit" />,
    component: <SignInCentered />,
    isLogout: true,
    hiddenFromToolbar: true,
  },
];

export default routes;
