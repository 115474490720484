import React, { useEffect, useMemo, useState } from "react";

// Chakra imports
import { Box, Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";

// Custom components
// import { MdAttachMoney, MdOutlinePendingActions } from "react-icons/md";
import { MdContacts, MdOutlineAttachMoney } from "react-icons/md";
import { FaFire, FaUsers } from "react-icons/fa";

import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import DailyTraffic from "views/admin/rtl/components/DailyTraffic";
import apiRequest from "utils/api";

const Home = () => {
  // Chakra Color Mode
  const [statistics, setStatistics] = useState({
    totalExtractedNumbers: "جاري التحميل...",
    totalUsers: "جاري التحميل...",
    profits: "جاري التحميل...",
    mostUsedPackage: "جاري التحميل...",
  });
  const secoundyColor = useMemo(() => "#25B697", []);
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiRequest(
          `/admin/statistics/getAdminStatistics`,
          "POST"
        );
        if (response.code === 200) {
          setStatistics({
            totalExtractedNumbers: response.statics?.totalExtractedNumbers,
            totalUsers: response.statics?.totalUsers,
            profits: response.statics?.profits,
            mostUsedPackage: response.statics?.mostUsedPackage?.title,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const formatNumber = (number = 0) => {
    if (isNaN(number)) {
      return number;
    }

    if (number >= 1e6) return (number / 1e6).toFixed(1) + "M"; // Millions
    if (number >= 1e3) return (number / 1e3).toFixed(1) + "K"; // Thousands
    return number.toString();
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mb="20px">
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="33px" h="33px" as={FaFire} color={secoundyColor} />
              }
            />
          }
          name="الباقه الاكثر مبيعاً"
          value={statistics.mostUsedPackage}
        />
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="33px" h="33px" as={MdContacts} color={secoundyColor} />
              }
            />
          }
          name="جميع الارقام المستخرجه"
          title={statistics.totalExtractedNumbers.toString()}
          value={formatNumber(statistics.totalExtractedNumbers)}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="33px" h="33px" as={FaUsers} color={secoundyColor} />
              }
            />
          }
          name="المستخدمين"
          title={statistics.totalUsers.toString()}
          value={formatNumber(statistics.totalUsers)}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="33px"
                  h="33px"
                  as={MdOutlineAttachMoney}
                  color={secoundyColor}
                />
              }
            />
          }
          name="الارباح"
          details="اخر شهر"
          title={statistics.profits.toString()}
          value={
            statistics?.profits?.toString()?.includes("جاري التحميل")
              ? statistics.profits
              : `${formatNumber(statistics.profits)}$`
          }
        />
      </SimpleGrid>

      <Box mb="20px">
        <DailyTraffic name="الارباح" numOfMessages={statistics.messageSent} />
      </Box>
      {/* <Box mb="20px">
        <TotalSpent />
      </Box> */}
    </Box>
  );
};

export default Home;
