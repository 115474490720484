// Chakra Imports
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import avatarHelp from "../../assets/img/avatars/default.png";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { GrUserAdmin } from "react-icons/gr";

// Assets
import { MdNotificationsNone } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import routes from "routes";
import IconBox from "components/icons/IconBox";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { socket } from "../../socket";
import apiRequest from "utils/api";

export default function HeaderLinks(props) {
  const user = useSelector(({ user }) => user.data);
  const toast = useToast();

  const [notifications, setNotifications] = useState({
    data: [],
    isLoading: true,
  });
  const [newNotifications, setNewNotifications] = useState(0);

  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 768);
  const { secondary } = props;

  // Effect to add and clean up resize event listener
  useEffect(() => {
    const handleResize = () => {
      setIsLaptop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    socket.on("notification", (notification) => {
      if (!notification.seen) {
        setNewNotifications((prev) => ++prev);
      }
    });

    return () => {
      socket.off("notification");
    };
  }, []);

  useEffect(() => {
    const fetchNewNotifications = async () => {
      try {
        const data = await apiRequest(
          `/notification/countNotifications/${user.token}`
        );

        if (data.code !== 200) {
          return toast({
            title: "حدث خطأ",
            description: data.message,
            status: "error",
            duration: 3000,
          });
        }

        setNewNotifications(data?.newNotifications || 0);
      } catch (e) {
        toast({
          title: "حدث خطأ",
          description: e?.response?.data?.message || e.message,
          status: "error",
          duration: 3000,
        });
      }
    };

    fetchNewNotifications();
  }, []);

  const getNotifications = async () => {
    try {
      const data = await apiRequest(
        `/notification/seenNotifications/${user.token}`
      );
      if (data.code !== 200) {
        return toast({
          title: "حدث خطأ",
          description: data.message,
          status: "error",
          duration: 3000,
        });
      }

      setNotifications({ isLoading: false, data: data.notifications });
      setNewNotifications(0);
    } catch (e) {
      toast({
        title: "حدث خطأ",
        description: e?.response?.data?.message || e.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const ethColor = useColorModeValue("gray.700", "white");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
    >
      <SidebarResponsive routes={routes} />

      <Menu background="unset !important" boxShadow="unset">
        <Box as={Flex} alignItems={"center"} gap={3}>
          {/* User Profile */}
          <Box display={{ xl: "none" }}>
            <Link p="0px" to="/profile" mx="10px">
              <Avatar
                src={avatarHelp}
                boxSize="56px"
                border="2px solid #ececec"
                bg="#fff"
              />
            </Link>
          </Box>

          {/* Admin Panel */}
          {user.isAdmin &&
            (isLaptop ? (
              <Link p="0px" to="/admin">
                <IconBox
                  w="56px"
                  h="56px"
                  border="2px solid #ececec"
                  icon={
                    <Icon
                      as={GrUserAdmin}
                      color={"#e5b71c"}
                      w="25px"
                      h="25px"
                    />
                  }
                />
              </Link>
            ) : (
              <Link p="0px" to="/admin" style={{ height: "fit-content" }}>
                <IconBox
                  w="25px"
                  h="25px"
                  icon={
                    <Icon
                      as={GrUserAdmin}
                      color={"#e5b71c"}
                      w="25px"
                      h="25px"
                    />
                  }
                  mx="10px"
                />
              </Link>
            ))}

          {/* Notification */}
          {isLaptop ? (
            <MenuButton
              onClick={getNotifications}
              p="0px"
              position={"relative"}
            >
              <IconBox
                w="56px"
                h="56px"
                border="2px solid #ececec"
                icon={
                  <Icon
                    as={MdNotificationsNone}
                    color={"#e5b71c"}
                    w="25px"
                    h="25px"
                  />
                }
              />

              {newNotifications > 0 && (
                <Box
                  position="absolute"
                  top="8px"
                  right="8px"
                  bg="red.500"
                  color="white"
                  fontSize="12px"
                  fontWeight="bold"
                  borderRadius="full"
                  w="18px"
                  h="18px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {newNotifications > 9 ? "9+" : newNotifications}
                </Box>
              )}
            </MenuButton>
          ) : (
            <MenuButton
              onClick={getNotifications}
              p="0px"
              position={"relative"}
            >
              <IconBox
                mt="6px"
                icon={
                  <Icon
                    as={MdNotificationsNone}
                    color={"#e5b71c"}
                    w="25px"
                    h="25px"
                  />
                }
                color={navbarIcon}
                w="25px"
                h="25px"
                ms="18px"
                me="14px"
              />

              {newNotifications > 0 && (
                <Box
                  position="absolute"
                  top="8px"
                  right="8px"
                  bg="red.500"
                  color="white"
                  fontSize="12px"
                  fontWeight="bold"
                  borderRadius="full"
                  w="18px"
                  h="18px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {newNotifications > 9 ? "9+" : newNotifications}
                </Box>
              )}
            </MenuButton>
          )}
        </Box>

        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: "30px", md: "unset" }}
          minW={{ base: "70%", md: "400px", xl: "450px" }}
          maxW={{ base: "360px", md: "400px" }}
        >
          <Flex w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              الإشعارات
            </Text>
          </Flex>
          <Flex flexDirection="column" maxH="300px" overflowY="auto">
            {notifications.isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                w="100%"
                h="100px"
                cursor="default"
              >
                <Spinner
                  size="md"
                  color="#7e4699"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                />
              </Box>
            ) : (
              <>
                {notifications.data.map((notification, idx) => (
                  <MenuItem
                    cursor="default"
                    closeOnSelect={false}
                    key={idx}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    px="0"
                    borderRadius="8px"
                    mb="10px"
                  >
                    <ItemContent
                      title={notification.title}
                      description={notification.body}
                      icon="notification"
                    />
                  </MenuItem>
                ))}
                <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  closeOnSelect={false}
                  px="0"
                  borderRadius="8px"
                  mb="10px"
                >
                  <ItemContent
                    title="مرحباً بك معنا"
                    description="رساله ترحيبية"
                  />
                </MenuItem>
              </>
            )}
          </Flex>
        </MenuList>
      </Menu>

      <Flex
        // bg={ethBg}
        display={secondary ? "flex" : "none"}
        borderRadius="30px"
        me="auto"
        p="6px"
        align="center"
        ms="6px"
      >
        <Flex
          align="center"
          justify="center"
          // bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="30px"
          ms="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: "none", md: "unset" }}>
            {" "}
            ETH
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
