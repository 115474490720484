import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    isLoading: true,
    data: {},
  },
  reducers: {
    setUserData: (state, action) => {
      state.isAuthenticated = true;
      state.data = action.payload;
    },
    activeAccountSwitch: (state, action) => {
      state.data.activeWhtsappAccountId = action.payload;
    },
    clearUserData: (state) => {
      state.isAuthenticated = false;
      state.data = {};
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload.loading;
    },
  },
});

export const { setUserData, clearUserData, setIsLoading, activeAccountSwitch } =
  userSlice.actions;

export default userSlice.reducer;
