import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/ar";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Text,
  useToast,
  SimpleGrid,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";

moment.locale("ar");

const Packages = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editPackage, setEditPackage] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    prices: {
      monthly: "",
      quarterly: "",
      semiAnnually: "",
      annually: "",
    },
    limits: {
      maxMessages: "",
      maxAccounts: "",
      maxNumberCollected: "",
      maxUrlFinding: "",
    },
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  // جلب الباقات الحالية
  const fetchPackages = async () => {
    setLoading(true);
    try {
      const response = await apiRequest("/package/getPackages", "GET", null, {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      });
      if (response.code === 200) {
        setPackages(response.packages);
      } else {
        toast({
          title: "خطأ",
          description: response.message || "فشل في جلب الباقات.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: error.message || "حدث خطأ أثناء جلب الباقات.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  // فتح نموذج التعديل
  const handleEditClick = (pkg) => {
    setEditPackage(pkg);
    setFormData({
      title: pkg.title,
      prices: {
        monthly: pkg.prices.monthly,
        quarterly: pkg.prices.quarterly,
        semiAnnually: pkg.prices.semiAnnually,
        annually: pkg.prices.annually,
      },
      limits: {
        maxMessages: pkg.limits.maxMessages,
        maxAccounts: pkg.limits.maxAccounts,
        maxNumberCollected: pkg.limits.maxNumberCollected,
        maxUrlFinding: pkg.limits.maxUrlFinding,
      },
    });
    onOpen();
  };

  // إدارة تغييرات النموذج
  const handleChange = (e) => {
    const { name, value } = e.target;

    // تحديث الحقول الرئيسية مثل العنوان
    if (name === "title") {
      setFormData((prev) => ({
        ...prev,
        title: value,
      }));
    }
  };

  // إدارة تغييرات الحقول الفرعية (prices و limits)
  const handleNestedChange = (section, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  // إدارة إرسال النموذج
  const handleSubmit = async () => {
    // التحقق من صحة الحقول المطلوبة
    if (!formData.title.trim()) {
      toast({
        title: "خطأ في الإدخال",
        description: "يرجى ملء عنوان الباقة.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // يمكن إضافة تحقق إضافي هنا مثل التأكد من أن الأسعار والحدود أرقام صحيحة

    // تجهيز البيانات المرسلة
    const payload = {
      token: localStorage.getItem("token"),
      packageId: editPackage._id,
      title: formData.title,
      prices: {
        monthly: Number(formData.prices.monthly),
        quarterly: Number(formData.prices.quarterly),
        semiAnnually: Number(formData.prices.semiAnnually),
        annually: Number(formData.prices.annually),
      },
      limits: {
        maxMessages: Number(formData.limits.maxMessages),
        maxAccounts: Number(formData.limits.maxAccounts),
        maxNumberCollected: Number(formData.limits.maxNumberCollected),
        maxUrlFinding: Number(formData.limits.maxUrlFinding),
      },
    };

    try {
      setSubmitLoading(true);
      const response = await apiRequest(
        "/admin/package/updatePackage",
        "POST",
        payload,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      );

      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: "تم تحديث الباقة بنجاح.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // تحديث الباقات في الحالة المحلية
        setPackages((prev) =>
          prev.map((pkg) =>
            pkg._id === editPackage._id
              ? { ...pkg, ...payload, updatedAt: new Date().toISOString() }
              : pkg
          )
        );
        onClose();
      } else {
        toast({
          title: "فشل التحديث",
          description: response.message || "حدث خطأ أثناء تحديث الباقة.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: error.message || "حدث خطأ أثناء تحديث الباقة.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Box
      pt={{ base: "130px !important", md: "120px" }}
      p={5}
      maxW="1200px"
      mx="auto"
    >
      {loading ? (
        <Box textAlign="center" py={10}>
          <Spinner size="xl" />
        </Box>
      ) : (
        <Box bg="white" boxShadow="md" borderRadius="md" p={6}>
          {packages.length === 0 ? (
            <Text>لا توجد باقات متاحة.</Text>
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>عنوان الباقة</Th>
                    <Th>الأسعار</Th>
                    <Th>الحدود</Th>
                    <Th>تاريخ اخر تحديث</Th>
                    <Th>الإجراءات</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {packages.map((pkg) => (
                    <Tr key={pkg._id}>
                      <Td>{pkg.title}</Td>
                      <Td>
                        <VStack align="start" spacing={1}>
                          <Text>شهريًا: ${pkg.prices.monthly}</Text>
                          <Text>ربع سنوي: ${pkg.prices.quarterly}</Text>
                          <Text>نصف سنوي: ${pkg.prices.semiAnnually}</Text>
                          <Text>سنويًا: ${pkg.prices.annually}</Text>
                        </VStack>
                      </Td>
                      <Td>
                        <VStack align="start" spacing={1}>
                          <Text>رسائل: {pkg.limits.maxMessages}</Text>
                          <Text>حسابات: {pkg.limits.maxAccounts}</Text>
                          <Text>
                            أرقام مجمعة: {pkg.limits.maxNumberCollected}
                          </Text>
                          <Text>اكتشاف روابط: {pkg.limits.maxUrlFinding}</Text>
                        </VStack>
                      </Td>
                      <Td>{moment(pkg.updatedAt).fromNow()}</Td>
                      <Td>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleEditClick(pkg)}
                        >
                          تعديل
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}

      {/* نموذج التعديل */}
      {editPackage && (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader bg="#7e4699" color="white" textAlign="center">
              تعديل الباقة: {editPackage.title}
            </ModalHeader>
            <ModalCloseButton color={"white"} />
            <ModalBody>
              <SimpleGrid columns={{ base: 1 }} spacing={5}>
                {/* عنوان الباقة */}
                <FormControl isRequired>
                  <FormLabel>عنوان الباقة</FormLabel>
                  <Input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* الأسعار */}
                <Box>
                  <FormLabel>الأسعار</FormLabel>
                  <HStack spacing={4}>
                    <FormControl>
                      <FormLabel>شهريًا</FormLabel>
                      <NumberInput
                        min={0}
                        value={formData.prices.monthly}
                        onChange={(value) =>
                          handleNestedChange("prices", "monthly", value)
                        }
                      >
                        <NumberInputField placeholder="شهريًا" />
                      </NumberInput>
                    </FormControl>
                    <FormControl>
                      <FormLabel>ربع سنوي</FormLabel>
                      <NumberInput
                        min={0}
                        value={formData.prices.quarterly}
                        onChange={(value) =>
                          handleNestedChange("prices", "quarterly", value)
                        }
                      >
                        <NumberInputField placeholder="ربع سنوي" />
                      </NumberInput>
                    </FormControl>
                    <FormControl>
                      <FormLabel>نصف سنوي</FormLabel>
                      <NumberInput
                        min={0}
                        value={formData.prices.semiAnnually}
                        onChange={(value) =>
                          handleNestedChange("prices", "semiAnnually", value)
                        }
                      >
                        <NumberInputField placeholder="نصف سنوي" />
                      </NumberInput>
                    </FormControl>
                    <FormControl>
                      <FormLabel>سنويًا</FormLabel>
                      <NumberInput
                        min={0}
                        value={formData.prices.annually}
                        onChange={(value) =>
                          handleNestedChange("prices", "annually", value)
                        }
                      >
                        <NumberInputField placeholder="سنويًا" />
                      </NumberInput>
                    </FormControl>
                  </HStack>
                </Box>

                {/* الحدود */}
                <Box>
                  <FormLabel>الحدود</FormLabel>
                  <VStack align="start" spacing={3}>
                    <FormControl>
                      <FormLabel>عدد الرسائل القصوى</FormLabel>
                      <NumberInput
                        min={0}
                        value={formData.limits.maxMessages}
                        onChange={(value) =>
                          handleNestedChange("limits", "maxMessages", value)
                        }
                      >
                        <NumberInputField placeholder="عدد الرسائل القصوى" />
                      </NumberInput>
                    </FormControl>
                    <FormControl>
                      <FormLabel>عدد الحسابات القصوى</FormLabel>
                      <NumberInput
                        min={0}
                        value={formData.limits.maxAccounts}
                        onChange={(value) =>
                          handleNestedChange("limits", "maxAccounts", value)
                        }
                      >
                        <NumberInputField placeholder="عدد الحسابات القصوى" />
                      </NumberInput>
                    </FormControl>
                    <FormControl>
                      <FormLabel>عدد الأرقام المجمعة القصوى</FormLabel>
                      <NumberInput
                        min={0}
                        value={formData.limits.maxNumberCollected}
                        onChange={(value) =>
                          handleNestedChange(
                            "limits",
                            "maxNumberCollected",
                            value
                          )
                        }
                      >
                        <NumberInputField placeholder="عدد الأرقام المجمعة القصوى" />
                      </NumberInput>
                    </FormControl>
                    <FormControl>
                      <FormLabel>عدد اكتشاف الروابط القصوى</FormLabel>
                      <NumberInput
                        min={0}
                        value={formData.limits.maxUrlFinding}
                        onChange={(value) =>
                          handleNestedChange("limits", "maxUrlFinding", value)
                        }
                      >
                        <NumberInputField placeholder="عدد اكتشاف الروابط القصوى" />
                      </NumberInput>
                    </FormControl>
                  </VStack>
                </Box>
              </SimpleGrid>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                إلغاء
              </Button>
              <Button
                bg="#7e4699"
                color="white"
                _hover={{ bg: "#6b3a8c" }}
                onClick={handleSubmit}
                isLoading={submitLoading}
              >
                تحديث الباقة
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default Packages;
