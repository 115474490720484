import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Text,
  FormControl,
  FormLabel,
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Tabs,
  TabList,
  Tab,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";

const Profile = () => {
  const user = useSelector(({ user }) => user.data);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPricing, setSelectedPricing] = useState("annually");

  const [subscriptionType, setSubscriptionType] = useState("");
  const [formattedExpiryDate, setFormattedExpiryDate] = useState("");

  const [packages, setPackages] = useState({
    isLoading: true,
    packages: [],
  });
  const [userPackage, setUserPackage] = useState({
    isLoading: true,
    package: {},
  });

  const handlePackageSelect = (pkg) => {
    setSelectedPackage(pkg._id);
  };

  const selectPackage = () => {
    if (!selectedPackage) return;

    setSelectedPackage(null);
    onClose();
  };

  const fetchPackages = async () => {
    try {
      setPackages((prev) => ({ ...prev, isLoading: true }));
      const response = await apiRequest(`/package/getPackages`);

      if (response.code === 200) {
        setPackages({ isLoading: false, packages: response.packages });

        // الحصول على الباقة الحالية للمستخدم بناءً على packageId
        const currentPackage = response.packages.find(
          (pkg) => pkg._id === user.packageId
        );

        if (currentPackage) {
          setUserPackage({ isLoading: false, package: currentPackage });
        } else {
          setUserPackage({ isLoading: false, package: null });
        }
      }
    } catch (error) {
      console.error("Error fetching packages:", error);
      setPackages((prev) => ({ ...prev, isLoading: false }));
    }
  };

  // استخدام useEffect لجلب الباقات عند تحميل الصفحة
  useEffect(() => {
    if (!user?._id) return;
    fetchPackages();
  }, [user]);

  useEffect(() => {
    if (user && user.subscriptionStartDate && user.subscriptionEndDate) {
      const subscriptionStartDate = new Date(user.subscriptionStartDate);
      const subscriptionEndDate = new Date(user.subscriptionEndDate);

      const diffTime = Math.abs(subscriptionEndDate - subscriptionStartDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      let type = "";
      if (diffDays <= 31) {
        type = "شهري";
        setSelectedPricing("monthly");
      } else if (diffDays <= 93) {
        type = "ربع سنوي";
        setSelectedPricing("quarterly");
      } else if (diffDays <= 186) {
        type = "نصف سنوي";
        setSelectedPricing("semiAnnually");
      } else {
        type = "سنوي";
        setSelectedPricing("annually");
      }
      setSubscriptionType(type);

      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedDate = subscriptionEndDate.toLocaleDateString(
        "ar-EG",
        options
      );
      setFormattedExpiryDate(formattedDate);
    }
  }, [user]);

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Box my={8} />

        <Text fontSize={"2xl"} fontWeight={"bold"} mb={6}>
          المعلومات الشخصية
        </Text>

        <Box
          as="form"
          background={"#f7f8f8"}
          py={8}
          px={"50px"}
          borderRadius={"40px"}
        >
          <Box
            as={Flex}
            gap={5}
            flexDirection={{ base: "column", md: "row" }}
            mb={"15px"}
          >
            <FormControl id="fname">
              <FormLabel fontWeight={"bold"}>الاسم الأول</FormLabel>
              <Input
                readOnly
                background={"#fff"}
                py={6}
                px={3}
                borderRadius={"15px"}
                type="text"
                value={user.firstName || ""}
                placeholder="احمد"
              />
            </FormControl>

            <FormControl id="lname">
              <FormLabel fontWeight={"bold"}>الاسم الأخير</FormLabel>
              <Input
                readOnly
                background={"#fff"}
                py={6}
                px={3}
                borderRadius={"15px"}
                value={user.lastName || ""}
                type="text"
                placeholder="اشرف"
              />
            </FormControl>
          </Box>

          <FormControl id="email" mb={"15px"}>
            <FormLabel fontWeight={"bold"}>البريد الالكتروني</FormLabel>
            <Input
              readOnly
              background={"#fff"}
              py={6}
              px={3}
              borderRadius={"15px"}
              type="email"
              value={user.email || ""}
              placeholder="AhmedAshraf@gmail.com"
            />
          </FormControl>

          <FormControl id="phone" mb={"15px"}>
            <FormLabel fontWeight={"bold"}>رقم الجوال</FormLabel>
            <Input
              readOnly
              background={"#fff"}
              py={6}
              px={3}
              borderRadius={"15px"}
              value={user.phone || ""}
              type="text"
              placeholder="+966 xxx xxx xx"
            />
          </FormControl>

          <FormControl id="package" mb={"15px"}>
            <FormLabel fontWeight={"bold"}>الباقة الحالية</FormLabel>

            <Box
              background={"#f9f9f9"}
              p={6}
              borderRadius={"15px"}
              borderWidth={1}
              borderColor={"gray.300"}
              boxShadow="md"
            >
              {packages.isLoading || userPackage.isLoading ? (
                <Text>جاري تحميل الباقات...</Text>
              ) : (
                <>
                  <Text
                    fontSize="2xl"
                    fontWeight="bold"
                    mb={4}
                    color="teal.600"
                  >
                    {userPackage.package?.title}
                  </Text>

                  {/* عرض السعر مع نوع الاشتراك وتاريخ الانتهاء */}
                  <Box mb={4}>
                    <Text fontSize="lg" fontWeight="semibold">
                      السعر الحالي:
                    </Text>
                    <Text fontSize="2xl" color="green.500" fontWeight="bold">
                      ${userPackage.package?.prices[selectedPricing]}\
                      <Text as={"span"} fontSize={"sm"}>
                        {subscriptionType}
                      </Text>
                    </Text>
                    <Text fontSize="md">
                      تاريخ انتهاء الاشتراك:{" "}
                      <Text as={"span"} fontWeight={"bold"}>
                        {formattedExpiryDate}
                      </Text>
                    </Text>
                  </Box>

                  {/* مميزات الباقة */}
                  <Box>
                    <Text fontSize="lg" fontWeight="semibold" mb={2}>
                      مميزات الباقة:
                    </Text>
                    <UnorderedList spacing={2} styleType="none">
                      <ListItem>
                        <Flex align="center">
                          <Box
                            me={"5px !important"}
                            ms={"0 !important"}
                            as="span"
                            color="teal.500"
                            mr={2}
                          >
                            ✔
                          </Box>
                          <Text>
                            أقصى عدد للرسائل:{" "}
                            <strong>
                              {userPackage.package?.limits.maxMessages}
                            </strong>
                          </Text>
                        </Flex>
                      </ListItem>
                      <ListItem>
                        <Flex align="center">
                          <Box
                            me={"5px !important"}
                            ms={"0 !important"}
                            as="span"
                            color="teal.500"
                            mr={2}
                          >
                            ✔
                          </Box>
                          <Text>
                            أقصى عدد للحسابات:{" "}
                            <strong>
                              {userPackage.package?.limits.maxAccounts}
                            </strong>
                          </Text>
                        </Flex>
                      </ListItem>
                      <ListItem>
                        <Flex align="center">
                          <Box
                            me={"5px !important"}
                            ms={"0 !important"}
                            as="span"
                            color="teal.500"
                            mr={2}
                          >
                            ✔
                          </Box>
                          <Text>
                            أقصى عدد للأرقام المجمعة:{" "}
                            <strong>
                              {userPackage.package?.limits.maxNumberCollected}
                            </strong>
                          </Text>
                        </Flex>
                      </ListItem>
                      <ListItem>
                        <Flex align="center">
                          <Box
                            me={"5px !important"}
                            ms={"0 !important"}
                            as="span"
                            color="teal.500"
                            mr={2}
                          >
                            ✔
                          </Box>
                          <Text>
                            أقصى عدد للروابط:{" "}
                            <strong>
                              {userPackage.package?.limits.maxUrlFinding}
                            </strong>
                          </Text>
                        </Flex>
                      </ListItem>
                    </UnorderedList>
                  </Box>

                  {/* الأزرار */}
                  <Flex
                    flexDirection={{ base: "column", lg: "row" }}
                    mt={6}
                    gap={4}
                  >
                    <Button
                      background={"#011040CC"}
                      _hover={{ background: "#202841" }}
                      px={5}
                      py={{ base: 3 }}
                      color={"#fff"}
                      flex={1}
                    >
                      تجديد الباقة
                    </Button>

                    <Button
                      background={"#25B6974D"}
                      px={5}
                      _hover={{ background: "green", color: "#fff" }}
                      color={"#25B697"}
                      py={{ base: 3 }}
                      onClick={onOpen}
                      flex={1}
                    >
                      تغيير الباقة
                    </Button>
                  </Flex>
                </>
              )}
            </Box>
          </FormControl>
        </Box>
      </Box>

      {/* Change Package Model */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader ms={4}>اختر باقتك الجديد</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* اختيار طريقة السعر باستخدام Tabs */}
            <Tabs
              defaultIndex={3}
              variant="enclosed-colored"
              colorScheme="teal"
              onChange={(index) => {
                const pricingOptions = [
                  "monthly",
                  "quarterly",
                  "semiAnnually",
                  "annually",
                ];
                setSelectedPricing(pricingOptions[index]);
              }}
              dir="rtl"
            >
              <TabList>
                <Tab>شهرياً</Tab>
                <Tab>ربع سنوي</Tab>
                <Tab>نصف سنوي</Tab>
                <Tab>سنوياً</Tab>
              </TabList>
            </Tabs>

            {/* عرض الباقات */}
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={4} mt={4}>
              {packages.isLoading || userPackage.isLoading ? (
                <Text>جاري تحميل الباقات...</Text>
              ) : (
                packages.packages
                  .filter((pkj) => pkj?._id !== userPackage.package?._id)
                  .map((pkg) => (
                    <Card
                      key={pkg._id}
                      borderWidth={selectedPackage === pkg._id ? 2 : 1}
                      borderColor={
                        selectedPackage === pkg._id ? "teal.500" : "gray.200"
                      }
                      borderRadius="lg"
                      cursor="pointer"
                      onClick={() => handlePackageSelect(pkg)}
                      _hover={{ borderColor: "teal.300", boxShadow: "lg" }}
                    >
                      <CardHeader textAlign="center">
                        <Text fontSize="2xl" fontWeight="bold">
                          {pkg.title}
                        </Text>
                        {/* يمكنك إضافة شارة لإظهار الباقة الأكثر شعبية */}
                        {pkg.isPopular && (
                          <Badge colorScheme="green" mt={2}>
                            الأكثر شعبية
                          </Badge>
                        )}
                      </CardHeader>
                      <CardBody>
                        <Box textAlign="center" mb={4}>
                          <Text
                            fontSize="3xl"
                            color="teal.600"
                            fontWeight="bold"
                          >
                            ${pkg.prices[selectedPricing]}
                          </Text>
                          <Text fontSize="md">
                            /{" "}
                            {selectedPricing === "monthly"
                              ? "شهر"
                              : selectedPricing === "quarterly"
                              ? "3 أشهر"
                              : selectedPricing === "semiAnnually"
                              ? "6 أشهر"
                              : "سنة"}
                          </Text>
                        </Box>
                        <Box>
                          <Text fontWeight="semibold" mb={2}>
                            مميزات الباقة:
                          </Text>
                          <UnorderedList spacing={1} styleType="none">
                            <ListItem>
                              <Flex align="center">
                                <Box
                                  me={"5px !important"}
                                  ms={"0 !important"}
                                  as="span"
                                  color="teal.500"
                                  mr={2}
                                >
                                  ✔
                                </Box>
                                <Text>
                                  عدد الرسائل:{" "}
                                  <strong>{pkg.limits.maxMessages}</strong>
                                </Text>
                              </Flex>
                            </ListItem>
                            <ListItem>
                              <Flex align="center">
                                <Box
                                  me={"5px !important"}
                                  ms={"0 !important"}
                                  as="span"
                                  color="teal.500"
                                  mr={2}
                                >
                                  ✔
                                </Box>
                                <Text>
                                  عدد الحسابات:{" "}
                                  <strong>{pkg.limits.maxAccounts}</strong>
                                </Text>
                              </Flex>
                            </ListItem>
                            <ListItem>
                              <Flex align="center">
                                <Box
                                  me={"5px !important"}
                                  ms={"0 !important"}
                                  as="span"
                                  color="teal.500"
                                  mr={2}
                                >
                                  ✔
                                </Box>
                                <Text>
                                  عدد الارقام المحفوظة:{" "}
                                  <strong>
                                    {pkg.limits.maxNumberCollected}
                                  </strong>
                                </Text>
                              </Flex>
                            </ListItem>
                            <ListItem>
                              <Flex align="center">
                                <Box
                                  me={"5px !important"}
                                  ms={"0 !important"}
                                  as="span"
                                  color="teal.500"
                                  mr={2}
                                >
                                  ✔
                                </Box>
                                <Text>
                                  عدد الروابط:{" "}
                                  <strong>{pkg.limits.maxUrlFinding}</strong>
                                </Text>
                              </Flex>
                            </ListItem>
                          </UnorderedList>
                        </Box>
                      </CardBody>
                      <CardFooter>
                        <Button
                          colorScheme="teal"
                          w="full"
                          onClick={() => handlePackageSelect(pkg)}
                        >
                          اختر هذه الباقة
                        </Button>
                      </CardFooter>
                    </Card>
                  ))
              )}
            </SimpleGrid>
          </ModalBody>

          <ModalFooter
            as={Flex}
            justifyContent={"center"}
            alignItems={"center"}
            gap={3}
          >
            <Button
              background={"#25B6974D"}
              px={5}
              _hover={{ background: "green", color: "#fff" }}
              color={"#25B697"}
              onClick={selectPackage}
            >
              تغيير الباقة
            </Button>

            <Button colorScheme="red" onClick={onClose}>
              اغلاق
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Profile;
