import React, { useEffect, useState } from "react";

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  Avatar,
  Text,
  Link,
  HStack,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import Links from "components/sidebar/components/Links";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import userAvatar from "assets/img/avatars/default.png";
import "../../assets/css/Contact.css";

// Assets
import { IoMenuOutline } from "react-icons/io5";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FaAngleLeft } from "react-icons/fa6";
import { clearUserData } from "state/userSlice";
import authService from "utils/authService";

function Sidebar(props) {
  const { routes } = props;
  const user = useSelector((state) => state.user?.data);
  const [logoutRoute, setLogoutRoute] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Object.keys(logoutRoute).length) {
      setLogoutRoute(routes.find((route) => route.isLogout));
    }
  }, [routes]);

  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarMargins = "0px";

  const logoutHandelar = () => {
    localStorage.removeItem("token");
    dispatch(clearUserData());
    authService.logout();
    navigate("/auth/sign-in");
  };

  // SIDEBAR
  return (
    <Box
      display={{ sm: "none", xl: "block" }}
      w="300px"
      position="fixed"
      minH="100vh"
    >
      <Box
        border="1px solid #ececec"
        borderRadius="35px"
        transition={variantChange}
        w="300px"
        h="15vh"
        mt={"30px"}
        mb={"10px"}
        minH="100%"
        overflowX="hidden"
        boxShadow={shadow}
        px={5}
      >
        <NavLink to={`/profile`} _hover={{ textDecoration: "none" }}>
          <Flex alignItems={"center"} justifyContent={"space-between"} h="100%">
            <Flex gap={"13px"}>
              <Avatar src={userAvatar} width={"50px"} />

              <Box>
                <Text
                  width={"150px"}
                  overflow={"hidden"}
                  fontWeight={"bold"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                >{`${user.firstName} ${user.lastName}`}</Text>
                <Text
                  width={"150px"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  fontWeight={"400"}
                  fontSize={"sm"}
                  whiteSpace={"nowrap"}
                >
                  {user?.email}
                </Text>
              </Box>
            </Flex>

            <Box>
              <FaAngleLeft size={"25px"} />
            </Box>
          </Flex>
        </NavLink>
      </Box>

      <Box
        border="1px solid #ececec"
        borderRadius="35px"
        transition={variantChange}
        w="300px"
        m={sidebarMargins}
        minH="100%"
        className="sidebar_id5341531"
        overflowX="hidden"
        boxShadow={shadow}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <Content
            routes={
              props.isAdminRoute
                ? routes.filter((route) => route.adminRoute)
                : routes.filter((route) => !route.adminRoute)
            }
          />
        </Scrollbars>
      </Box>

      <Box
        onClick={logoutHandelar}
        border="2px solid #ececec"
        cursor={"pointer"}
        borderRadius="30px"
        transition={variantChange}
        w="300px"
        py={"5px"}
        mt={"25px"}
        overflowX="hidden"
        color={"red"}
        backgroundColor={"#fff"}
        boxShadow={shadow}
        _hover={{ backgroundColor: "red", color: "#fff" }}
      >
        <Box>
          <HStack py="5px">
            <Flex
              py={"10px"}
              borderRadius={"20px"}
              w="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Text
                margin={"auto"}
                me="auto"
                fontSize={"17px"}
                fontWeight={"bold"}
              >
                {logoutRoute.name}
              </Text>
            </Flex>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props) {
  const location = useLocation();
  let menuColor = useColorModeValue("gray.400", "white");

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;
  // let isWindows = navigator.platform.startsWith("Win");
  //  BRAND

  useEffect(() => {
    if (isOpen) {
      onClose();
    }
  }, [location]);

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="285px"
          maxW="285px"
          bg={"#fff"}
          border={"2px solid #ececec"}
        >
          <DrawerCloseButton
            zIndex="3"
            onClose={onClose}
            color={"#000"}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content
                routes={
                  location.pathname.startsWith("/admin")
                    ? routes.filter(
                        (route) => route.adminRoute || route.isLogout
                      )
                    : routes.filter((route) => !route.adminRoute)
                }
              />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
