import "./assets/css/App.css";
import AuthLayout from "./layouts/auth";
import Layout from "./layouts/layout";
import initialTheme from "./theme/theme";
import useAuth from "Hooks/useAuth";

import { Routes, Route, Navigate } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { socket } from "./socket";
import LoadingScreen from "Pages/Users/LoadingScreen";

import { useDispatch } from "react-redux";
import { setUserData, setIsLoading } from "./state/userSlice";

const App = () => {
  const { isAuthenticated, loading, userData } = useAuth("user");
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  useEffect(() => {
    // Event listener for socket connection
    const handleConnect = () => {
      setIsConnected(true);
    };

    // Attach event listener
    socket.on("connect", handleConnect);

    // Cleanup function to remove event listener on unmount
    return () => {
      socket.off("connect", handleConnect);
    };
  }, []);

  useEffect(() => {
    if (isConnected && userData?._id?.length && !loading) {
      socket.emit("register", { userId: userData?._id });
    }
  }, [isConnected, userData?._id, loading]);

  useEffect(() => {
    if (userData) {
      dispatch(setUserData(userData));
    }
    dispatch(setIsLoading({ loading }));
  }, [userData, loading, dispatch]);

  if (loading) return <LoadingScreen />;

  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        {/* Protected routes */}
        {isAuthenticated ? (
          <>
            <Route
              path="/*"
              element={
                <Layout theme={currentTheme} setTheme={setCurrentTheme} />
              }
            />
            <Route path="auth/*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <Route path="/*" element={<Navigate to="/auth/sign-in" replace />} />
        )}

        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="auth/*" element={<AuthLayout />} />
      </Routes>
    </ChakraProvider>
  );
};

export default App;
