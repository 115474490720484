import React from "react";

// components
import { Box } from "@chakra-ui/react";

// Assets
import SectionsComponents from "components/SectionsComponents";

const Sections = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SectionsComponents />
    </Box>
  );
};

export default Sections;
