import React, { useEffect, useState } from "react";
import loginImage from "../../../assets/img/auth/log_out.png";
import otpImage from "../../../assets/img/auth/sign_in.png";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  Image,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import default styles for the library
import arabicCountries from "react-phone-input-2/lang/ar.json";
import { useNavigate } from "react-router-dom";
import apiRequest from "utils/api";

const Signup = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [phone, setPhone] = useState(""); // Phone number state
  const [otp, setOtp] = useState(new Array(4).fill("")); // OTP state, an array of 4 fields
  const [step, setStep] = useState(1); // Track the current step (1 = phone number, 2 = OTP)
  const [error, setError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [packageType, setPackageType] = useState("");
  const [packages, setPackages] = useState([]);

  const [subscriptionDuration, setSubscriptionDuration] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  // payment
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [billingAddress, setBillingAddress] = useState("");

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await apiRequest("/package/getPackages", "GET");
        setPackages(response.packages || []);
      } catch (error) {
        setError("فشل في جلب الباقات.");
      }
    };
    fetchPackages();
  }, []);

  const handlePayment = () => {
    // Validate the payment inputs
    if (!creditCardNumber || !expirationDate || !cvv || !billingAddress) {
      setError("يرجى ملء جميع حقول الدفع"); // Error message in Arabic
      return;
    }

    // Simulate payment processing (replace with real payment processing logic)
    const isPaymentSuccessful = true; // Simulate payment success

    if (isPaymentSuccessful) {
      setError(""); // Clear error message
      // Navigate to the confirmation page or success page
      navigate("/confirmation");
    } else {
      setError("فشل في معالجة الدفع"); // Error message in Arabic
    }
  };

  const validate = () => {
    const validations = [
      { condition: !firstName, message: "الاسم الأول مطلوب" },
      { condition: !lastName, message: "الاسم الأخير مطلوب" },
      { condition: !email, message: "البريد الإلكتروني مطلوب" },
      {
        condition: email && !/\S+@\S+\.\S+/.test(email),
        message: "عنوان البريد الإلكتروني غير صالح",
      },
      { condition: !packageType, message: "نوع الحزمة مطلوب" },
      {
        condition: !subscriptionDuration,
        message: "مدة الاشتراك مطلوبة",
      },
      { condition: !phone, message: "رقم الهاتف مطلوب" },
    ];

    for (const { condition, message } of validations) {
      if (condition) {
        setError(message);
        return false; // Return false if there's an error
      }
    }

    return true; // Return true if no errors
  };

  // Simulate sending OTP
  const handleSubmit = async () => {
    setError("");
    if (!validate()) return;
    setIsSubmitting(true);

    try {
      const response = await apiRequest("/auth/signup", "POST", {
        firstName,
        lastName,
        email,
        phone: `+${phone}`,
        packageId: packageType,
        subscriptionDuration,
      });

      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: response.message,
          status: "success",
        });
        setStep(2); // الانتقال لخطوة OTP
      } else {
        setError(response.error || response.message);
      }
    } catch (error) {
      setError(error.response.data.errors || error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resendCode = () => {
    if (isResendDisabled) return;

    handleSubmit();
    setIsResendDisabled(true);
  };

  // Handle OTP input in multiple fields
  const handleOtpChange = (value, index) => {
    if (isNaN(value) || value === " ") return; // Check if the input is a number

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move to the next input field
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  // Verify the entered OTP
  const verifyOtp = async () => {
    const otpValue = otp.join("");
    if (otpValue.length !== 4) {
      setError("يرجى إدخال الرمز بالكامل.");
      return;
    }

    setIsVerifying(true);
    try {
      const response = await apiRequest("/auth/checkotp", "POST", {
        phone: `+${phone}`,
        otp: otpValue,
        otpType: "signup",
      });

      if (response.code === 200) {
        toast({
          title: "تم التحقق بنجاح",
          description: "تم إنشاء الحساب بنجاح",
          status: "success",
        });

        localStorage.setItem("token", response?.user?.token);
        window.location.reload();
      } else {
        setOtp(new Array(4).fill(""));
        document.getElementById(`otp-0`).focus();
        setError(response.message);
      }
    } catch (error) {
      setOtp(new Array(4).fill(""));
      document.getElementById(`otp-0`).focus();
      setError(error?.response?.data?.message || error?.message);
    } finally {
      setIsVerifying(false);
    }
  };

  const backStep = () => {
    setStep(1);

    setError("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPackageType("");
    setSubscriptionDuration("");
    setPhone("");
    setOtp(new Array(4).fill(""));
  };

  useEffect(() => {
    if (otp.join("").length >= 4) {
      verifyOtp();
    }
  }, [otp]);

  return (
    <Box py={10}>
      {/* Mopile Screen Heading */}
      <Heading
        display={{ base: "block", md: "none" }}
        as="h2"
        size="lg"
        textAlign="center"
      >
        {step === 1 ? "إنشاء حساب جديد" : step === 2 ? "تأكيد الرقم" : "الدفع"}
      </Heading>
      <Text
        display={{ base: "block", md: "none" }}
        align={"center"}
        color={"#6c757d"}
        fontSize={14}
        mt={"15px !important"}
      >
        {step === 1
          ? "الرجاء إدخال المعلومات الخاصة بك أدناه من أجل إنشاء حساب جديد"
          : step === 2
          ? `الرجاء إدخال الرمز المكون من 4 أرقام الذي أرسلناه إليك`
          : "أدخل تفاصيل الدفع"}
      </Text>

      <Flex
        minH="100vh"
        align={{ base: "start", md: "center" }}
        justify="center"
        p={6}
      >
        <Flex
          flexDirection={{ base: "column-reverse", md: "row" }}
          w={{ base: "100%", md: "70%", lg: "60%" }}
          flexWrap={"wrap"}
        >
          {/* Left side */}
          <VStack
            spacing={{ base: 0, md: 6 }}
            w={{ base: "100%", md: "50%" }}
            p={{ base: 1, md: 8 }}
            align="stretch"
            justify="center"
          >
            {/* lg Screen heading */}
            <Heading
              display={{ base: "none", md: "block" }}
              as="h2"
              size="lg"
              textAlign="center"
            >
              {step === 1
                ? "إنشاء حساب جديد"
                : step === 2
                ? "تأكيد الرقم"
                : "الدفع"}
            </Heading>
            <Text
              display={{ base: "none", md: "block" }}
              align={"center"}
              color={"#6c757d"}
              fontSize={14}
              mt={"15px !important"}
            >
              {step === 1
                ? "الرجاء إدخال المعلومات الخاصة بك أدناه من أجل إنشاء حساب جديد"
                : step === 2
                ? `الرجاء إدخال الرمز المكون من 4 أرقام الذي أرسلناه إليك`
                : "أدخل تفاصيل الدفع"}
            </Text>
            {step === 2 ? (
              <Flex
                mt={"13px !important"}
                alignItems={"center"}
                justifyContent={"center"}
                color={"#6c757d"}
                gap={2}
                fontSize={15}
                pb={{ base: "15px", md: "0" }}
              >
                <Text
                  onClick={backStep}
                  color={"#25B697"}
                  fontWeight={"bold"}
                  as={"button"}
                >
                  تعديل
                </Text>

                <Text>{phone.startsWith("+") ? phone : `+${phone}`}</Text>
              </Flex>
            ) : (
              ""
            )}
            {/* Form for each step */}
            {step === 1 ? (
              <VStack spacing={4}>
                {/* Input for first name */}
                <Input
                  placeholder="الاسم الأول"
                  dir="rtl"
                  py={"25px"}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {/* Input for last name */}
                <Input
                  placeholder="الاسم الثاني"
                  dir="rtl"
                  py={"25px"}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {/* Input for email */}
                <Input
                  placeholder="البريد الإلكتروني"
                  dir="rtl"
                  py={"25px"}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* Country selection and phone input component */}
                <PhoneInput
                  country={"sa"} // Default country
                  value={phone}
                  placeholder="+xx xxx xxx xxx"
                  localization={arabicCountries} // Set localization for Arabic
                  onChange={setPhone} // Update phone number state
                  inputStyle={{ width: "100%", height: "50px" }} // Customize input field style
                  buttonStyle={{ backgroundColor: "#EAEAEA" }} // Customize flag button style
                  onEnterKeyPress={handleSubmit}
                />

                {/* Package selection */}
                <Select
                  placeholder="اختر الباقة"
                  value={packageType}
                  onChange={(e) => setPackageType(e.target.value)}
                >
                  {packages.map((pkg) => (
                    <option key={pkg._id} value={pkg._id}>
                      {pkg.title}
                    </option>
                  ))}
                </Select>

                {/* Subscription duration selection */}
                <Select
                  placeholder="مدة الاشتراك"
                  value={subscriptionDuration}
                  onChange={(e) => setSubscriptionDuration(e.target.value)}
                >
                  <option value="monthly">شهري</option>
                  <option value="quarterly">ربع سنوي</option>
                  <option value="semiAnnually">نصف سنوي</option>
                  <option value="annually">سنوي</option>
                </Select>

                {error && (
                  <Text color="red.500" fontSize="sm">
                    {error}
                  </Text>
                )}
                <Flex
                  mt={"16px !important"}
                  w={"100%"}
                  color={"#6c757d"}
                  fontSize={14}
                >
                  <Text
                    onClick={() => navigate("/auth/sign-in")}
                    color={"#25B697"}
                    fontWeight={"bold"}
                    as={"button"}
                    me={1}
                  >
                    تسجيل الدخول
                  </Text>
                  <Text>لديك حساب بالفعل ؟</Text>
                </Flex>
                <Button
                  mt={"25px !important"}
                  colorScheme="teal"
                  size="lg"
                  py={"28px"}
                  bg={"#7E4699"}
                  _hover={{ bg: "#533064" }}
                  width="full"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  loadingText="جاري التحقق"
                >
                  تسجيل
                </Button>
              </VStack>
            ) : step === 2 ? (
              <VStack spacing={4}>
                {/* OTP input fields */}
                <HStack justify="center">
                  {otp.map((data, index) => (
                    <Input
                      key={index}
                      placeholder="X"
                      id={`otp-${index}`}
                      type="text"
                      maxLength="1"
                      size="lg"
                      fontSize={"25px"}
                      textAlign="center"
                      value={data}
                      onChange={(e) => handleOtpChange(e.target.value, index)}
                      width={"4rem"}
                      height={"4rem"}
                      autoComplete="off"
                      onKeyUp={(e) => {
                        if (e.key === "Backspace" && index) {
                          document.getElementById(`otp-${index - 1}`).focus();
                        }
                      }}
                    />
                  ))}
                </HStack>
                {error && (
                  <Text color="red.500" fontSize="sm">
                    {error} {/* Display error message */}
                  </Text>
                )}
                <Button
                  colorScheme="teal"
                  size="lg"
                  width="full"
                  py={"28px"}
                  bg={"#7E4699"}
                  _hover={{ bg: "#533064" }}
                  onClick={verifyOtp} // Verify OTP button
                  isLoading={isVerifying}
                  loadingText="جاري التحقق"
                >
                  تحقق
                </Button>

                {!isResendDisabled && (
                  <Text
                    color="#25B697"
                    fontSize="sm"
                    mt="2"
                    fontWeight={"bold"}
                    cursor="pointer"
                    onClick={resendCode}
                  >
                    إعادة إرسال كود التحقق
                  </Text>
                )}
              </VStack>
            ) : step === 3 ? (
              <VStack spacing={4}>
                <Input
                  placeholder="رقم بطاقة الائتمان"
                  dir="rtl"
                  py={"25px"}
                  value={creditCardNumber}
                  onChange={(e) => setCreditCardNumber(e.target.value)}
                />

                {/* Input for expiration date */}
                <HStack>
                  <Input
                    placeholder="تاريخ الانتهاء (MM/YY)"
                    dir="rtl"
                    py={"25px"}
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(e.target.value)}
                  />
                  <Input
                    placeholder="CVV"
                    dir="rtl"
                    py={"25px"}
                    value={cvv}
                    m={"0 !important"}
                    onChange={(e) => setCvv(e.target.value)}
                  />
                </HStack>

                {/* Input for billing address */}
                <Input
                  placeholder="عنوان الفوترة"
                  dir="rtl"
                  py={"25px"}
                  value={billingAddress}
                  onChange={(e) => setBillingAddress(e.target.value)}
                />

                {error && (
                  <Text color="red.500" fontSize="sm">
                    {error}
                  </Text>
                )}

                <Button
                  colorScheme="teal"
                  size="lg"
                  width="full"
                  py={"28px"}
                  bg={"#7E4699"}
                  _hover={{ bg: "#533064" }}
                  onClick={handlePayment} // Function to handle payment submission
                  loadingText="جاري المعالجة"
                >
                  أكمل الدفع
                </Button>
              </VStack>
            ) : null}
          </VStack>

          {/* Right side - Image */}
          <Box
            m={"auto"}
            w={{ base: "80%", md: "50%" }}
            border={{ md: "2px solid #EAEAEA" }}
            borderRadius={"35px"}
            color="white"
            px={{ base: 1, md: 8 }}
            py={{ base: "20px", md: "70px" }}
          >
            <Flex align="center" justify="center">
              <Image
                src={step === 1 ? loginImage : step === 2 ? otpImage : otpImage}
                alt="Illustration"
                boxSize="80%"
              />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Signup;
