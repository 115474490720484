/* eslint-disable */
import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { clearUserData } from "state/userSlice";
import authService from "utils/authService";

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("#fff", "white");
  let inactiveColor = useColorModeValue("#fff", "white");
  let textColor = useColorModeValue("#011040");
  let activeIcon = useColorModeValue("white", "white");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    if (location.pathname.includes("/admin")) {
      return location.pathname.toLowerCase() === routeName.toLowerCase();
    } else {
      return location.pathname.toLowerCase().includes(routeName.toLowerCase());
    }
  };

  const logoutHandelar = () => {
    localStorage.removeItem("token");
    dispatch(clearUserData());
    authService.logout();
    navigate("/auth/sign-in");
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route?.hiddenFromToolbar && route?.isLogout) {
        return (
          <Box
            key={index}
            onClick={logoutHandelar}
            border="2px solid #ececec"
            display={{ base: "block", xl: "none" }}
            cursor={"pointer"}
            borderRadius="30px"
            transition={"0.2s linear"}
            py={"5px"}
            mt={"25px"}
            overflowX="hidden"
            color={"red"}
            backgroundColor={"#fff"}
          >
            <Box>
              <HStack py="5px">
                <Flex
                  py={"10px"}
                  borderRadius={"20px"}
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    margin={"auto"}
                    me="auto"
                    fontSize={"17px"}
                    fontWeight={"bold"}
                  >
                    {route.name}
                  </Text>
                </Flex>
              </HStack>
            </Box>
          </Box>
        );
      } else if (route?.hiddenFromToolbar) {
        return "";
      } else if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt="18px"
              pb="12px"
              key={index}
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (route.layout === "/rtl") {
        return (
          <NavLink key={index} to={route.path}>
            {route.icon ? (
              <Box>
                <HStack spacing={"26px"} py="5px" ps="10px">
                  <Flex
                    backgroundColor={
                      activeRoute(route.path.toLowerCase())
                        ? "#7e4699 !important"
                        : "unset"
                    }
                    p={"20px"}
                    borderRadius={"20px"}
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                    _hover={{ backgroundColor: "gray.200" }}
                    transition="background-color 0.3s ease"
                  >
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      alignItems="center"
                      justifyContent="center"
                      display={"flex"}
                      me="18px"
                    >
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                  </Flex>
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py="5px"
                  ps="10px"
                >
                  <Text
                    me="auto"
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                  >
                    {route.name}
                  </Text>
                  <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };

  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
