import React, { useCallback, useEffect, useState } from "react";

// components
import { Box, useToast } from "@chakra-ui/react";

// Assets
import ExportContactsNumber from "components/ExportContactsNumber";
import apiRequest from "utils/api";
import CollapsibleCard from "components/CollapsibleCard";
import { useSelector } from "react-redux";

const ExportContacts = () => {
  const user = useSelector((state) => state.user?.data);
  const toast = useToast();

  const [userAccountsData, setUserAccountsData] = useState({
    accounts: [],
    activeAccount: {},
    isLoading: true,
    isHave: false,
  });

  const fetchAcc = useCallback(async () => {
    if (!Object.keys(user).length) return;

    try {
      const data = await apiRequest("/whatsapp/getAccounts", "POST");

      if (data.code !== 200) {
        setUserAccountsData((prev) => ({ ...prev, isLoading: false }));

        return toast({
          title: "حدث خطأ",
          description: data.message,
          status: "error",
          duration: 3000,
        });
      }

      setUserAccountsData({
        isLoading: false,
        accounts: data?.accounts,
        activeAccount:
          data?.accounts.find(
            (acc) => acc._id === user?.activeWhtsappAccountId
          ) || data?.accounts[0],
        isHave: data?.accounts?.length > 0,
      });
    } catch (e) {
      setUserAccountsData((prev) => ({ ...prev, isLoading: false }));
      toast({
        title: "حدث خطأ",
        description: e?.response?.data?.message || e.message,
        status: "error",
        duration: 3000,
      });
    }
  }, [user]);

  useEffect(() => {
    fetchAcc();
  }, [fetchAcc]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box my={8} />
      <CollapsibleCard
        disabled={
          !userAccountsData.isHave ||
          userAccountsData.isLoading ||
          !userAccountsData?.activeAccount?.syncing
        }
        disabledMsg={
          userAccountsData.isLoading
            ? "جاري البحث عن حسابك..."
            : !userAccountsData.isHave
            ? "يجب تسجيل حساب واتساب للمتابعة"
            : !userAccountsData?.activeAccount?.syncing
            ? "الحساب المختار في مرحلة المزامنه انتظر انتهاء المزامنه او غير الحساب"
            : ""
        }
        isOpen={userAccountsData.isHave}
        title="سحب ارقام المجموعات"
      >
        <ExportContactsNumber
          activeAccountId={userAccountsData?.activeAccount?._id}
        />
      </CollapsibleCard>
    </Box>
  );
};

export default ExportContacts;
