import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
  SimpleGrid,
  Checkbox,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Box,
  HStack,
  Avatar,
  Center,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { BsSend } from "react-icons/bs";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";
import { socket } from "socket";
import { CircleLoader } from "react-spinners";
import CollapsibleCard from "components/CollapsibleCard";

const MessageForm = () => {
  const [formData, setFormData] = useState({
    sectionId: "",
    message: "",
    messageImage: null,
    messageUrl: "",
    delay: 0,
    deleteAfterSend: false,
  });
  const fileImageRef = useRef(null);

  const user = useSelector((state) => state.user?.data);
  const toast = useToast();

  const [isBotWork, setIsBotWork] = useState(false);
  const [isBotWorkLoading, setIsBotWorkLoading] = useState(false);
  const [isBotStopLoading, setIsBotStopLoading] = useState(false);

  const [allGroupsSelected, setAllGroupsSelected] = useState(false);
  const [residual, setResidual] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [sectionsOptions, setSectionsOptions] = useState([]);

  const [isCardOpen, setIsCardOpen] = useState(false);
  const [groupLoading, setGroupLoading] = useState(true);

  const [isGroupMessage, setIsGroupMessage] = useState(false);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [requestSent, setRequestSent] = useState(false);

  const getSections = useCallback(async () => {
    try {
      const response = await apiRequest(`/sections/getSections`, "POST");
      let sections = response?.sections?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      sections = sections?.map((section) => ({
        name: section.title,
        id: section._id,
      }));
      setSectionsOptions(sections);
    } catch (e) {
      toast({
        title: "حدث خطأ أثناء جلب الأقسام",
        description: e?.response?.data?.message || e.message,
        status: "error",
        duration: 3000,
      });
    }
  }, [user?._id, toast]);

  // Handel Buttons Status
  const getStatus = useCallback(async () => {
    try {
      setIsBotWorkLoading(true);

      const response = await apiRequest(`/whatsapp/getBotStatus`, "POST", {
        accountId: user?.activeWhtsappAccountId,
      });
      if (response.code === 200) {
        setIsBotWork(response?.isWork);
        setResidual(response?.tasks);
      } else {
        toast({
          title: "مشكله",
          description: "حدث مشكله برجاء الاتصال بالمطور",
          status: "error",
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "مشكله",
        description: "حدث مشكله برجاء الاتصال بالمطور",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsBotWorkLoading(false);
    }
  }, []);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, deleteAfterSend: e.target.checked });
  };

  // Handle image file change
  const handleImageChange = (e) => {
    setFormData({ ...formData, messageImage: e.target.files[0] });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form validation and submission logic here
    if ((!isGroupMessage && !formData.sectionId) || !formData.message) {
      toast({
        title: "خطأ",
        description: "يرجي ملئ جميع الحقول",
        status: "error",
        duration: 5000,
        position: "bottom-right",
      });
      return;
    }

    if (isGroupMessage && !selectedGroups.length) {
      toast({
        title: "خطأ",
        description: "يرجي ملئ جميع الحقول",
        status: "error",
        duration: 5000,
        position: "bottom-right",
      });
      return;
    }

    if (formData.delay) {
      if (formData.delay > 30) {
        return toast({
          title: "خطأ",
          description: "وقت الانتظار بين الرسائل بحد اقصي 30 ثانيه",
          status: "error",
          duration: 5000,
          position: "bottom-right",
        });
      }

      if (formData.delay < 5) {
        return toast({
          title: "خطأ",
          description: "وقت الانتظار بين الرسائل بحد ادني 5 ثواني",
          status: "error",
          duration: 5000,
          position: "bottom-right",
        });
      }
    }

    // Prepare the form data
    const data = new FormData();
    data.append("whtsappAccountId", user.activeWhtsappAccountId);
    data.append("message", formData.message);
    formData.messageImage && data.append("messageImage", formData.messageImage);
    formData.messageUrl && data.append("messageUrl", formData.messageUrl);
    data.append("delay", formData.delay || 5);

    if (isGroupMessage) {
      data.append("groupIds", selectedGroups);
    } else {
      data.append("deleteAfterSend", formData.deleteAfterSend);
      data.append("sectionId", formData.sectionId);
    }

    try {
      setSubmitLoading(true);
      const response = await apiRequest(
        `/whatsapp/${isGroupMessage ? "sendMessagesGroups" : "sendMessages"}`,
        "POST",
        data
      );

      if (response.code === 200) {
        await getStatus();

        toast({
          title: "نجاح",
          description: "تم تشغيل البرنامج بنجاح",
          status: "success",
          duration: 5000,
          position: "bottom-right",
        });

        // Reset form after submission if needed
        setFormData({
          sectionId: "",
          message: "",
          messageImage: null,
          messageUrl: "",
          delay: 0,
          deleteAfterSend: false,
        });

        setSelectedGroups([]);

        if (fileImageRef.current) {
          fileImageRef.current.value = null;
        }
      } else {
        toast({
          title: "خطأ",
          description: response?.message,
          status: "error",
          duration: 5000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description:
          error?.response?.data?.message ||
          error?.response?.data?.errors ||
          error?.message,
        status: "error",
        duration: 5000,
        position: "bottom-right",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleSelectGroup = (groupId) => {
    setSelectedGroups((prev) => {
      const newSelection = prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId];
      setAllGroupsSelected(newSelection.length === groupOptions.length); // تحديث حالة "تحديد الكل"
      return newSelection;
    });
  };

  const toggleSelectAllGroups = () => {
    if (allGroupsSelected) {
      setSelectedGroups([]);
    } else {
      setSelectedGroups(groupOptions.map((group) => group.id));
    }
    setAllGroupsSelected(!allGroupsSelected);
  };

  // stopBot
  const stopBot = async () => {
    try {
      setIsBotStopLoading(true);

      const response = await apiRequest(`/whatsapp/stopBot`, "POST", {
        accountId: user?.activeWhtsappAccountId,
      });
      if (response.code === 200) {
        await getStatus();

        toast({
          title: "تم",
          description: response?.message,
          status: "success",
          duration: 5000,
        });
      } else {
        toast({
          title: "مشكله",
          description: response?.message,
          status: "error",
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "مشكله",
        description:
          error.response.data.errors ||
          error.response.data.message ||
          "حدث مشكله برجاء الاتصال بالمطور",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsBotStopLoading(false);
    }
  };

  const getGroups = async () => {
    setGroupLoading(true);

    try {
      const response = await apiRequest(
        `/whatsapp/getGroups/${
          user.activeWhtsappAccountId
        }/${localStorage.getItem("token")}`
      );

      if (response.code === 200) {
        setRequestSent(true);
        toast({
          title: "تم",
          description: "جاري تحميل المجموعات من فضلك لا تغلق الصفحة",
          status: "warning",
          duration: 5000,
        });
      } else {
        toast({
          title: "مشكلة",
          description: "فشل جلب المجموعات",
          status: "error",
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "مشكلة",
        description: "فشل جلب المجموعات",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleToggleCard = () => {
    setIsCardOpen((prev) => !prev);
  };

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  useEffect(() => {
    getSections();
  }, [getSections]);

  useEffect(() => {
    // Listen for "bot_status" event
    socket.on("bot_status", async (status) => {
      const isWork = status?.isWork;
      const residual = status?.tasks?.residual;

      setIsBotWork(isWork);
      setResidual(residual);
    });

    socket.on("groupsExport", async ({ groupDetails }) => {
      let groupsOptions = groupDetails?.map((group) => ({
        id: group?.id,
        name: group?.name,
        members: group?.membersCount,
        image: group?.image,
      }));
      groupsOptions = groupsOptions.filter((group) => group?.members);

      setGroupOptions(groupsOptions);
      setGroupLoading(false);
    });

    // Cleanup listener on component unmount
    return () => {
      socket.off("bot_status");
      socket.off("groupsExport");
    };
  }, []);

  useEffect(() => {
    if (isGroupMessage && !requestSent) {
      getGroups();
    }
  }, [isGroupMessage, requestSent]);

  useEffect(() => {
    setAllGroupsSelected(
      selectedGroups.length === groupOptions.length && groupOptions.length > 0
    );
  }, [selectedGroups, groupOptions]);

  return (
    <Card mb={{ base: "0px", lg: "20px" }} px={10} pb={8}>
      <form onSubmit={handleSubmit}>
        <FormControl mb="20px">
          <FormLabel>اختر نوع الإرسال</FormLabel>
          <RadioGroup
            onChange={(value) => setIsGroupMessage(value === "group")}
            defaultValue="section"
          >
            <Stack direction="row">
              <Radio value="section">إلى قسم</Radio>
              <Radio value="group">إلى مجموعة</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        <SimpleGrid columns={{ md: isGroupMessage ? 1 : 2 }} columnGap={10}>
          {/* Select Section */}
          {isGroupMessage ? (
            <FormControl mb="20px">
              <VStack spacing={4} pt={4}>
                <CollapsibleCard
                  isOpen={isCardOpen}
                  onToggle={handleToggleCard}
                  title="مجموعاتك"
                >
                  <FormControl py={3}>
                    <Box as={Flex} flexWrap={"wrap"}>
                      <FormLabel>حدد من مجموعاتك:</FormLabel>
                      <Checkbox
                        mb={2}
                        isChecked={allGroupsSelected}
                        onChange={toggleSelectAllGroups}
                        isDisabled={groupOptions.length === 0}
                        fontWeight={"bold"}
                      >
                        تحديد الكل
                      </Checkbox>
                    </Box>

                    <Box h={270} overflowY="auto" w="100%">
                      {groupLoading ? (
                        <Center h="100%">
                          <Flex
                            direction="column"
                            justify="center"
                            align="center"
                          >
                            <CircleLoader color="#7e4699" />
                            <Text
                              mt={4}
                              fontSize={"24px"}
                              fontWeight="bold"
                              textShadow="0px 2px 4px rgba(0, 0, 0, 0.3)"
                            >
                              جاري التحميل...
                            </Text>
                          </Flex>
                        </Center>
                      ) : !groupOptions.length ? (
                        <Center h="100%">
                          <Text fontSize="lg" fontWeight="bold">
                            لا توجد مجموعات لعرضها
                          </Text>
                        </Center>
                      ) : (
                        groupOptions.map((group) => (
                          <HStack
                            key={group.id}
                            spacing={4}
                            py={2}
                            borderBottom="1px solid #eee"
                          >
                            <Checkbox
                              isChecked={selectedGroups.includes(group.id)}
                              onChange={() => handleSelectGroup(group.id)}
                            >
                              <HStack spacing={4}>
                                <Avatar
                                  src={group.image}
                                  boxSize="50px"
                                  alt={`${group.name} image`}
                                />
                                <Box>
                                  <Text fontWeight="bold">{group.name}</Text>
                                  <Text fontSize="sm" color="gray.500">
                                    {group.members} عضو
                                  </Text>
                                </Box>
                              </HStack>
                            </Checkbox>
                          </HStack>
                        ))
                      )}
                    </Box>
                    <Text mt={2} color="gray.500">
                      لقد حددت {selectedGroups.length} مجموعات
                    </Text>
                  </FormControl>
                </CollapsibleCard>
              </VStack>
            </FormControl>
          ) : (
            <FormControl mb="20px">
              <FormLabel>حدد القسم المرسل إليه</FormLabel>
              <Select
                placeholder="اختر القسم"
                name="sectionId"
                value={formData.sectionId}
                onChange={handleChange}
                icon={() => null}
              >
                {sectionsOptions.map((sectionOption) => (
                  <option key={sectionOption.id} value={sectionOption.id}>
                    {sectionOption.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Delay */}
          <FormControl mb="20px">
            <FormLabel>وقت الانتظار بين الرسائل (بالثواني)</FormLabel>
            <Input
              type="number"
              placeholder="أدخل عدد الثواني"
              name="delay"
              value={formData.delay}
              onChange={handleChange}
            />
          </FormControl>
        </SimpleGrid>

        {/* Text Area for Message */}
        <FormControl mb="20px">
          <FormLabel>اكتب نص الرسالة</FormLabel>
          <Textarea
            dir="rtl"
            rows={12}
            maxLength={1500}
            resize={"none"}
            placeholder="اكتب رسالتك هنا..."
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </FormControl>

        {/* Image Upload */}
        <FormControl mb="20px">
          <FormLabel>تحميل صورة للرسالة</FormLabel>
          <Input
            ref={fileImageRef}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            border="none"
            p="2"
            cursor="pointer"
          />
        </FormControl>

        {/* URL Input */}
        <FormControl mb="20px">
          <FormLabel>رابط للرسالة</FormLabel>
          <Input
            type="url"
            placeholder="https://example.com"
            name="messageUrl"
            value={formData.messageUrl}
            onChange={handleChange}
          />
        </FormControl>

        {!isGroupMessage && (
          <FormControl mb={10} mt={"10px"}>
            <Checkbox
              isChecked={formData.deleteAfterSend}
              onChange={handleCheckboxChange}
            >
              حذف المحادثه بعد الإرسال
            </Checkbox>
          </FormControl>
        )}

        {/* Send Button */}
        {isBotWorkLoading ? (
          <Button
            isLoading={true}
            loadingText="فحص مهام البرنامج"
            colorScheme="yellow"
            w="100%"
            h="50px"
          >
            إرسال
          </Button>
        ) : isBotWork ? (
          <>
            <Text mb={"10px"}>البرنامج مازال يعمل تبقا: {residual} رسائل</Text>

            <Button
              isLoading={isBotStopLoading}
              loadingText="يتم إيقاف البرنامج"
              type="button"
              onClick={stopBot}
              colorScheme="red"
              w="100%"
              h="50px"
            >
              ايقاف البرنامج
            </Button>
          </>
        ) : (
          <Button
            isLoading={submitLoading}
            loadingText="يتم الارسال"
            type="submit"
            colorScheme="green"
            w="100%"
            h="50px"
            rightIcon={<BsSend size={20} />}
          >
            إرسال
          </Button>
        )}
      </form>
    </Card>
  );
};

export default MessageForm;
