import React, { useCallback, useEffect, useRef, useState } from "react";

// مكونات Chakra UI
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Stack,
  useBreakpointValue,
  useToast,
  Spinner,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  Alert,
  AlertIcon,
  Tooltip,
  FormLabel,
  Image,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  IconButton,
} from "@chakra-ui/react";

import PhoneInput from "react-phone-input-2";
import arabicCountries from "react-phone-input-2/lang/ar.json";
import "react-phone-input-2/lib/style.css"; // استيراد الأنماط الافتراضية

// أيقونات
import { BiLogOut } from "react-icons/bi";
import { AiOutlineUserSwitch } from "react-icons/ai";

// صور
import defaultAvatar from "../../../assets/img/avatars/default.png";

// خدمات وأدوات أخرى
import apiRequest from "utils/api";
import VerifyMark from "components/VerifyMark";
import { useDispatch, useSelector } from "react-redux";
import { FaWhatsapp } from "react-icons/fa";

// استيراد Socket
import { socket } from "../../../socket"; // تأكد من مسار الاستيراد الصحيح
import { activeAccountSwitch } from "state/userSlice";
import { WarningIcon } from "@chakra-ui/icons";

// Duration for get qr code
const countdownDuration = 30;

const WhatsappAccounts = () => {
  const user = useSelector((state) => state.user?.data);
  const dispatch = useDispatch();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Count Down
  const [countdown, setCountdown] = useState(countdownDuration);
  const [countdownActive, setCountdownActive] = useState(false);

  const {
    isOpen: isSwitchModalOpen,
    onOpen: onSwitchModalOpen,
    onClose: onSwitchModalClose,
  } = useDisclosure();
  const {
    isOpen: isWarningOpen,
    onOpen: onWarningOpen,
    onClose: onWarningClose,
  } = useDisclosure();
  const [userAccountsData, setUserAccountsData] = useState({
    accounts: [],
    activeAccount: {},
    isLoading: true,
    isHave: false,
    isUserComplete: false,
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [qrCodeData, setQrCodeData] = useState(null);
  const [stage, setStage] = useState("input");

  // استخدام useRef لتخزين دوال المعالجة
  const handleQrCodeRef = useRef();
  const handleQrCodeActiveRef = useRef();

  const [doneQrButtonLoading, setDoneQrButtonLoading] = useState(false);
  const [islogoutBtnLoading, setIslogoutBtnLoading] = useState(false);
  const [isSwitchAccBtnLoading, setIsSwitchAccBtnLoading] = useState({});

  const fetchAcc = useCallback(async () => {
    if (!Object.keys(user).length) return;

    try {
      const data = await apiRequest("/whatsapp/getAccounts", "POST");

      if (data.code !== 200) {
        setUserAccountsData((prev) => ({ ...prev, isLoading: false }));

        return toast({
          title: "حدث خطأ",
          description: data.message,
          position: "bottom-right",
          status: "error",
          duration: 3000,
        });
      }

      setUserAccountsData({
        isLoading: false,
        accounts: data?.accounts,
        activeAccount:
          data?.accounts.find(
            (acc) => acc._id === user?.activeWhtsappAccountId
          ) || data?.accounts[0],
        isHave: data?.accounts?.length > 0,
        isUserComplete: data?.isUserComplete,
      });
    } catch (e) {
      setUserAccountsData((prev) => ({ ...prev, isLoading: false }));
      toast({
        title: "حدث خطأ",
        position: "bottom-right",
        description: e?.response?.data?.message || e.message,
        status: "error",
        duration: 3000,
      });
    }
  }, [user, toast]);

  // دالة لإضافة حساب جديد
  const handleAddAccount = async () => {
    // التحقق الأساسي
    if (!phoneNumber.trim()) {
      toast({
        title: "رقم الهاتف مطلوب",
        position: "bottom-right",
        description: "يرجى إدخال رقم هاتف صالح.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const data = await apiRequest("/whatsapp/addAccount", "POST", {
        phoneNumber: `+${phoneNumber.trim()}`,
      });

      if (data.code === 200) {
        toast({
          title: "نجاح",
          description: data.message,
          position: "bottom-right",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setStage("waiting"); // الانتقال إلى مرحلة انتظار رمز QR
      } else {
        // التعامل مع رموز الخطأ المحددة
        toast({
          title: "خطأ",
          position: "bottom-right",
          description: data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "حدث خطأ",
        position: "bottom-right",
        description: error?.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // دالة لإغلاق الـ Modal وتنظيف المستمعين
  const handleClose = useCallback(() => {
    onClose();
    setPhoneNumber("");
    setQrCodeData(null);
    setStage("input");
    setIsSubmitting(false);
    setCountdownActive(false);
  }, [onClose]);

  handleQrCodeActiveRef.current = useCallback(
    (data) => {
      if (!data.active_account) {
        handleClose();

        return toast({
          title: "خطأ",
          position: "bottom-right",
          description: "لقد حدث خطأ غير متوقع",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setDoneQrButtonLoading(true);

      fetchAcc().then(() => {
        setDoneQrButtonLoading(false);
        handleClose();
        // onWarningOpen();
      });
    },
    [fetchAcc, handleClose, toast]
  );

  // دالة لمعالجة رمز QR المستلم من Socket
  handleQrCodeRef.current = useCallback(
    (data) => {
      if (data?.qrCode && data?.phoneNumber === phoneNumber && isOpen) {
        setQrCodeData(data.qrCode);
        setStage("showQR");
      }
    },
    [isOpen, phoneNumber]
  );

  // دالة لفتح الـ Modal وإعادة تعيين الحقول
  const linkNewAccount = () => {
    setPhoneNumber("");
    setQrCodeData(null);
    setStage("input");
    onOpen();
    setCountdown(countdownDuration);
    setCountdownActive(true);
  };

  const logout = async () => {
    try {
      setIslogoutBtnLoading(true);

      const data = await apiRequest("/whatsapp/logout", "POST", {
        accountId: userAccountsData.activeAccount?._id,
      });

      if (data.code === 200) {
        fetchAcc().then(() => {
          toast({
            title: "نجاح",
            position: "bottom-right",
            description: data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        });
      } else {
        // التعامل مع رموز الخطأ المحددة
        toast({
          title: "خطأ",
          position: "bottom-right",
          description: data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "حدث خطأ",
        position: "bottom-right",
        description: error?.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIslogoutBtnLoading(false);
    }
  };

  const handleAccountSwitch = async (account) => {
    try {
      setIsSwitchAccBtnLoading((prev) => ({ ...prev, [account._id]: true }));

      const data = await apiRequest("/whatsapp/changeActiveProfile", "POST", {
        accountId: account?._id,
      });

      if (data.code === 200) {
        await fetchAcc().then(() => {
          setUserAccountsData((prev) => ({
            ...prev,
            activeAccount: account,
          }));
          account?._id && dispatch(activeAccountSwitch(account?._id));

          // close modal
          onSwitchModalClose();

          toast({
            title: "نجاح",
            position: "bottom-right",
            description: data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        });
      } else {
        toast({
          title: "خطأ",
          position: "bottom-right",
          description: data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "حدث خطأ",
        position: "bottom-right",
        description: error?.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSwitchAccBtnLoading((prev) => ({ ...prev, [account._id]: false }));
    }
  };

  useEffect(() => {
    fetchAcc();
  }, [fetchAcc]);

  useEffect(() => {
    const handleQrCode = (data) => handleQrCodeRef.current(data);
    const handleQrCodeActive = (data) => handleQrCodeActiveRef.current(data);

    socket.on("active_account", handleQrCodeActive);
    socket.on("qr_code", handleQrCode);

    return () => {
      socket.off("active_account", handleQrCodeActive);
      socket.off("qr_code", handleQrCode);
    };
  }, []);

  useEffect(() => {
    if (!countdownActive || countdown <= 0) return; // يعمل فقط إذا كان countdownActive صحيحًا
    const timer = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, [countdownActive, countdown]);

  useEffect(() => {
    if (stage === "waiting") {
      setCountdown(countdownDuration); // إعادة تعيين العد التنازلي إلى مدته الأصلية
      setCountdownActive(true); // تفعيل العد التنازلي
    } else {
      setCountdownActive(false); // إيقاف العد التنازلي
    }
  }, [stage]);

  // تحديد ما إذا كنا نعرض النص بناءً على حجم الشاشة
  const showText = useBreakpointValue({ base: false, md: true });

  if (userAccountsData.isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="purple.500" />
      </Center>
    );
  }

  return (
    <>
      <Box
        pt={{ base: "100px", md: "80px", xl: "80px" }}
        px={{ base: 4, md: 8 }}
      >
        <Box my={8} />
        <Box
          p={6}
          borderRadius={"20px"}
          border={"1px solid #EAEAEA"}
          as={Flex}
          direction={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          bg="white"
          boxShadow="md"
        >
          <Flex
            gap={4}
            alignItems={"center"}
            width={{ base: "100%", md: "auto" }}
            flexDir={{ base: "column", lg: "row" }}
            mb={{ base: 4, md: 0 }}
          >
            <Avatar
              src={
                userAccountsData.activeAccount?.accountDetails
                  ?.whatsappNumberAvatar || defaultAvatar
              }
              size="lg"
            />

            <Box textAlign={{ base: "center", md: "left" }} width="100%">
              <Flex
                justify={{ base: "center", md: "start" }}
                align="center"
                gap={2}
              >
                <Text fontSize="lg" fontWeight="bold">
                  {userAccountsData.activeAccount?.accountDetails
                    ?.whatsappName || "اسم مستخدم"}
                </Text>
                <VerifyMark verify={userAccountsData.activeAccount?._id} />
              </Flex>

              <Text
                dir="ltr"
                textAlign={{ base: "center", lg: "end" }} // اضبط المحاذاة هنا إلى "center" عند العرض على أجهزة صغيرة
                pe={"3px"}
                fontSize="sm"
                color="gray.500"
                mt={1} // إضافة تباعد بسيط بين النصوص
              >
                {userAccountsData.activeAccount?.phoneNumber
                  ? `+${userAccountsData.activeAccount?.phoneNumber}`
                  : "xxxxx-xxx-xxx"}
              </Text>

              {/* نص المزامنة */}
              {userAccountsData.activeAccount?._id &&
              !userAccountsData?.activeAccount?.syncing ? (
                <Alert
                  status="info"
                  overflow={"unset"}
                  textAlign={"center"}
                  justifyContent={"center"}
                  variant="left-accent"
                  mt={2}
                  bg="orange.50"
                  boxShadow="md"
                  py={2}
                  px={4}
                  color="orange.700"
                >
                  <Text fontSize="sm">جاري المزامنة...</Text>

                  <Popover placement="top" isLazy>
                    <PopoverTrigger>
                      <IconButton
                        icon={<AlertIcon color="orange.500" />}
                        colorScheme="orange"
                        variant="ghost"
                        size="sm"
                        aria-label="مزيد من المعلومات"
                        ms={2}
                      />
                    </PopoverTrigger>
                    <PopoverContent
                      color="white"
                      bg="orange.500"
                      borderColor="orange.600"
                      borderRadius="md"
                      boxShadow="lg"
                      maxWidth="250px"
                      fontSize="sm"
                      _focus={{ boxShadow: "none" }}
                    >
                      <PopoverArrow bg="orange.500" />
                      <PopoverBody>
                        لا يمكنك استخدام الحساب أثناء عملية المزامنة. سيتم إرسال
                        إشعار إليك عند اكتمالها، والتي تستغرق{" "}
                        <Text as={"span"} fontSize={"13px"} fontWeight={"bold"}>
                          عادةً من 5 دقائق إلى ساعتين كحد أقصى.
                        </Text>{" "}
                        <Text as={"span"} fontSize={"13px"} fontWeight={"bold"}>
                          يرجي ابقاء تطبيق واتساب مفتوحاً لسرعه المزامنه.
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Alert>
              ) : (
                <></>
              )}
            </Box>
          </Flex>

          <Stack
            direction={{ base: "column", sm: "row" }}
            spacing={4}
            width={{ base: "100%", md: "auto" }}
            display={{ base: "flex", md: "relative" }}
            justifyContent="center"
            alignItems="center"
          >
            {userAccountsData.activeAccount?._id ? (
              <>
                <Button
                  colorScheme="red"
                  iconSpacing={{ base: "0", md: 2 }}
                  variant="outline"
                  leftIcon={<BiLogOut size={20} />}
                  width={{ base: "100%", sm: "auto" }}
                  justifyContent="center"
                  onClick={logout}
                  isLoading={islogoutBtnLoading}
                >
                  {showText && "تسجيل خروج"}
                </Button>
                <Button
                  colorScheme="purple"
                  variant="outline"
                  iconSpacing={{ base: "0", md: 2 }}
                  leftIcon={<AiOutlineUserSwitch size={20} />}
                  width={{ base: "100%", sm: "auto" }}
                  justifyContent="center"
                  onClick={onSwitchModalOpen}
                >
                  {showText && "تبديل حساب"}
                </Button>
              </>
            ) : (
              <Button
                onClick={linkNewAccount}
                background={"#25B6974D"}
                _hover={{ background: "green", color: "#fff" }}
                color={"#25B697"}
                fontWeight={"bold"}
                rightIcon={<FaWhatsapp size={25} />}
                py={4}
                px={3}
                width={{ base: "100%", sm: "auto" }}
              >
                {showText && "ربط حسابك"}
              </Button>
            )}
          </Stack>
        </Box>
      </Box>

      {/* Add Account Modal */}
      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {stage === "showQR"
              ? "مسح الرمز لتفعيل حسابك"
              : "إضافة حساب واتساب جديد"}
          </ModalHeader>
          <ModalBody>
            {stage === "input" ? (
              // المرحلة الأولى: إدخال رقم الهاتف
              <FormControl id="phone-number" isRequired>
                <FormLabel>رقم الهاتف</FormLabel>

                <PhoneInput
                  country={"sa"} // الدولة الافتراضية
                  value={phoneNumber}
                  onChange={(phone) => setPhoneNumber(phone)}
                  placeholder="+xx xxx xxx xxx"
                  onEnterKeyPress={() => {
                    if (!isSubmitting) {
                      handleAddAccount();
                    }
                  }}
                  localization={arabicCountries} // تعيين اللغة العربية
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                    paddingLeft: "70px",
                  }} // تخصيص نمط حقل الإدخال
                  buttonStyle={{
                    backgroundColor: "#EAEAEA",
                    paddingInline: "10px",
                  }} // تخصيص نمط زر العلم
                  containerStyle={{ direction: "ltr" }} // تخصيص نمط الحاوية
                />
              </FormControl>
            ) : stage === "waiting" ? (
              // المرحلة الثانية: انتظار رمز QR مع Spinner
              <Flex direction="column" align="center" justify="center">
                <Text mb={4} fontSize="md">
                  جارٍ انتظار رمز الكيو اَر... ({Math.floor(countdown / 60)}:
                  {String(countdown % 60).padStart(2, "0")})
                </Text>
                <Spinner size="xl" color="purple.500" />
              </Flex>
            ) : (
              // المرحلة الثالثة: عرض رمز QR
              <Flex direction="column" align="center" justify="center">
                <Text mb={4} fontSize="md">
                  قم بمسح الرمز باستخدام تطبيق واتساب لتفعيل حسابك
                </Text>
                {qrCodeData ? (
                  <Image src={qrCodeData} alt="QR Code" boxSize="200px" />
                ) : (
                  <Spinner size="xl" color="purple.500" />
                )}
              </Flex>
            )}
          </ModalBody>

          <ModalFooter>
            {stage === "input" ? (
              <>
                <Button variant="ghost" mr={3} onClick={handleClose}>
                  إلغاء
                </Button>
                <Button
                  colorScheme="green"
                  onClick={handleAddAccount}
                  isLoading={isSubmitting}
                >
                  إضافة
                </Button>
              </>
            ) : stage === "showQR" ? (
              <Button
                colorScheme="red"
                isLoading={doneQrButtonLoading}
                onClick={handleClose}
              >
                الغاء العمليه
              </Button>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal Switch */}
      <Modal isOpen={isSwitchModalOpen} onClose={onSwitchModalClose} size="lg">
        <ModalOverlay />
        <ModalContent borderRadius="lg" boxShadow="lg">
          <ModalHeader fontSize="xl" fontWeight="bold" textAlign="center">
            تبديل الحساب
          </ModalHeader>
          <ModalBody>
            {userAccountsData?.accounts.map((account) => (
              <Box
                key={account._id}
                p={4}
                mb={3}
                borderRadius="md"
                border="1px solid #EAEAEA"
                bg="gray.50"
                _hover={{ bg: "gray.100" }}
              >
                <Flex align="center" justify="space-between">
                  <Flex align="center" gap={3}>
                    <Avatar
                      src={
                        account?.accountDetails?.whatsappNumberAvatar ||
                        defaultAvatar
                      }
                      size="md"
                    />
                    <Box>
                      <Text fontWeight="bold">
                        {account?.accountDetails?.whatsappName || "اسم مستخدم"}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {account?.phoneNumber
                          ? account?.phoneNumber?.startsWith("+")
                            ? account?.phoneNumber
                            : `${account?.phoneNumber}+`
                          : "xxxxx-xxx-xxx"}
                      </Text>
                      {!account.syncing && (
                        <Text fontSize="xs" color="orange.500" mt={1}>
                          جاري المزامنة...
                        </Text>
                      )}
                    </Box>
                  </Flex>
                  {userAccountsData.activeAccount._id === account._id ? (
                    <Box
                      px={2}
                      py={0.5}
                      bg="green.100"
                      color="green.700"
                      borderRadius="full" // تحويل الزوايا لتكون دائرية بالكامل
                      fontSize="sm" // تصغير حجم النص
                      fontWeight="bold"
                    >
                      محدد
                    </Box>
                  ) : (
                    <Button
                      colorScheme="red"
                      size="sm"
                      isLoading={isSwitchAccBtnLoading[account._id] || false}
                      onClick={() => handleAccountSwitch(account)}
                    >
                      اختيار
                    </Button>
                  )}
                </Flex>
              </Box>
            ))}

            {/* تصميم زر "أضف حساب" بشكل محسّن مع علامة "+" */}
            {!userAccountsData.isUserComplete && (
              <Box
                p={3}
                mt={4}
                borderRadius="md"
                border="1px solid #EAEAEA"
                bg="gray.50"
                _hover={{ bg: "gray.100" }}
                onClick={linkNewAccount}
                cursor="pointer"
                textAlign="center"
              >
                <Flex align="center" justify="center" gap={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="full"
                    bg="teal.500"
                    color="white"
                    width="24px"
                    height="24px"
                    fontSize="lg"
                  >
                    +
                  </Box>
                  <Text fontWeight="bold" color="teal.600">
                    أضف حساب
                  </Text>
                </Flex>
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button colorScheme="red" onClick={onSwitchModalClose}>
              إغلاق
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Warning Model */}
      <Modal isOpen={isWarningOpen} onClose={onWarningClose}>
        <ModalOverlay />
        <ModalContent bg="gray.200" color="black" borderRadius="lg">
          <ModalHeader display="flex" alignItems="center" gap={2}>
            <WarningIcon boxSize={6} color="orange.400" />
            <Text fontSize="lg" fontWeight="bold" color="orange.600">
              تنبيه هام
            </Text>
          </ModalHeader>
          <ModalBody>
            <Text fontSize="md">
              <Text fontWeight="bold">
                يرجى عدم فتح الحساب على أي منصة واتساب ويب أخرى،
              </Text>
              <Text fontWeight="medium">
                وفي حال القيام بذلك، يجب تسجيل الخروج من الحساب ثم إعادة تسجيل
                الدخول مجددًا
              </Text>
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              variant="solid"
              onClick={onWarningClose}
            >
              إغلاق
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WhatsappAccounts;
