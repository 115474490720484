import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/ar";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  SimpleGrid,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import apiRequest from "utils/api";
import PhoneInput from "react-phone-input-2"; // إضافة PhoneInput
import "react-phone-input-2/lib/style.css"; // استيراد الأنماط الافتراضية للمكتبة
import arabicCountries from "react-phone-input-2/lang/ar.json"; // استيراد الترجمة العربية

moment.locale("ar");

const Users = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); // For Add/Edit Modal
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure(); // For Delete Confirmation Modal

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isAdmin: false,
    isActive: false,
    packageId: "",
    subscriptionType: "", // Added subscriptionType
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [addUserLoading, setAddUserLoading] = useState(false);

  // State for packages
  const [packages, setPackages] = useState([]);
  const [packagesLoading, setPackagesLoading] = useState(true);
  const [packagesError, setPackagesError] = useState(null);

  // State for delete confirmation
  const [usersToDelete, setUsersToDelete] = useState([]);
  const cancelRef = React.useRef();

  // Helper function to compute subscriptionEndDate
  const computeSubscriptionEndDate = (subscriptionType) => {
    const now = moment();
    switch (subscriptionType) {
      case "monthly":
        return now.add(1, "months").toDate();
      case "quarterly":
        return now.add(3, "months").toDate();
      case "semiAnnually":
        return now.add(6, "months").toDate();
      case "annually":
        return now.add(12, "months").toDate();
      default:
        return null;
    }
  };

  // Fetch Users
  const fetchUsers = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const response = await apiRequest(`/admin/user/getUsers/${token}`, "GET");

      if (response.code === 200) {
        setUsers(
          response.users.map((user) => ({
            ...user,
            isSelected: false, // Initialize selection state
          }))
        );
      } else {
        toast({
          title: "خطأ",
          description: response.message || "فشل في جلب المستخدمين.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description:
          error?.response?.data?.message || "حدث خطأ أثناء جلب المستخدمين.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch Packages
  const fetchPackages = async () => {
    try {
      const response = await apiRequest(`/package/getPackages`, "GET");
      if (response.code === 200) {
        setPackages(response.packages);
      } else {
        toast({
          title: "خطأ",
          description: response.message || "فشل في جلب الباقات.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description:
          error?.response?.data?.message || "حدث خطأ أثناء جلب الباقات.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setPackagesLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchPackages();
  }, []);

  // Open Edit Modal
  const handleEditClick = (user) => {
    setEditUser(user);
    setFormData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      isAdmin: user.isAdmin,
      isActive: user.isActive,
      packageId: user.packageId?._id || "", // Set packageId to the ID from the package object
      subscriptionType: user.subscriptionType || "", // Ensure subscriptionType is present
    });
    onOpen();
  };

  // Open Add User Modal
  const handleAddUserClick = () => {
    setEditUser(null);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      isAdmin: false,
      isActive: true, // Set isActive to true by default عند إضافة مستخدم جديد
      packageId: "",
      subscriptionType: "", // Initialize subscriptionType
    });
    onOpen();
  };

  // Handle Form Changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle Phone Change
  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      phone: value,
    }));
  };

  // Handle Form Submission (Add or Update)
  const handleSubmit = async () => {
    // Validate required fields
    if (
      !formData.firstName.trim() ||
      !formData.lastName.trim() ||
      !formData.email.trim() ||
      !formData.phone.trim() || // Ensure phone is filled
      !formData.packageId || // Ensure packageId is selected when adding or editing
      (!editUser && !formData.subscriptionType) // Ensure subscriptionType is selected when adding
    ) {
      toast({
        title: "خطأ في الإدخال",
        description:
          "يرجى ملء الحقول المطلوبة (الاسم الأول، الاسم الأخير، البريد الإلكتروني، الهاتف، الباقة، نوع الاشتراك عند الإضافة).",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Prepare payload
    let payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      isAdmin: formData.isAdmin,
      isActive: formData.isActive,
      packageId: formData.packageId, // Ensure packageId is always sent
    };

    // For adding a user, include additional fields
    if (!editUser) {
      const subscriptionEndDate = computeSubscriptionEndDate(
        formData.subscriptionType
      );
      if (!subscriptionEndDate) {
        toast({
          title: "خطأ",
          description: "نوع الاشتراك غير صالح.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      payload = {
        ...payload,
        subscriptionEndDate: subscriptionEndDate.toISOString(), // Send as ISO string
      };
    }

    try {
      setSubmitLoading(true);
      toast({
        title: "جارٍ المعالجة...",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
      let response;
      if (editUser) {
        // Update user
        response = await apiRequest(
          `/admin/user/updateUser/${editUser._id}`,
          "POST",
          payload
        );
      } else {
        // Add new user
        setAddUserLoading(true);
        response = await apiRequest(`/admin/user/addUser`, "POST", payload);
        setAddUserLoading(false);
      }

      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: editUser
            ? "تم تحديث المستخدم بنجاح."
            : "تم إضافة المستخدم بنجاح.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Update the users state without refetching
        if (editUser) {
          setUsers((prev) =>
            prev.map((user) =>
              user._id === editUser._id
                ? {
                    ...user,
                    ...formData,
                    packageId: packages.find(
                      (pkg) => pkg._id === formData.packageId
                    ), // Update packageId with the package object
                    subscriptionType:
                      formData.subscriptionType || user.subscriptionType,
                  }
                : user
            )
          );
        } else {
          setUsers((prev) => [
            { ...response.data, isSelected: false },
            ...prev,
          ]);
        }
        onClose();
      } else {
        toast({
          title: "فشل العملية",
          description: response.message || "حدث خطأ أثناء العملية.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);

      toast({
        title: "خطأ",
        description: error?.response?.data?.message || "حدث خطأ أثناء العملية.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitLoading(false);
      setAddUserLoading(false);
    }
  };

  // Handle Batch Activation/Deactivation/Deletion
  const handleUpdateUsersActivation = async (type) => {
    const selectedUserIds = users
      .filter((user) => user.isSelected)
      .map((user) => user._id);

    if (selectedUserIds.length === 0) {
      toast({
        title: "لا يوجد مستخدمين محددين",
        description: "يرجى تحديد مستخدمين أولاً.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (type === "delete") {
      // Open delete confirmation modal
      setUsersToDelete(selectedUserIds);
      onDeleteOpen();
      return;
    }

    const payload = {
      token: localStorage.getItem("token"),
      usersIds: selectedUserIds,
      type: type, // "active", "de_active", "delete"
    };

    try {
      toast({
        title: "جارٍ المعالجة...",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
      const response = await apiRequest(
        `/admin/user/updateUsersActivation`,
        "POST",
        payload
      );

      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: `تم تحديث حالة المستخدمين بنجاح.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Updated state handling to reset isSelected and update isActive
        setUsers((prev) =>
          prev.map((user) => ({
            ...user,
            isActive: selectedUserIds.includes(user._id)
              ? type === "active"
              : user.isActive,
            isSelected: false, // Reset selection after update
          }))
        );
      } else {
        toast({
          title: "فشل التحديث",
          description: response.message || "حدث خطأ أثناء تحديث المستخدمين.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description:
          error?.response?.data?.message || "حدث خطأ أثناء تحديث المستخدمين.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle Confirm Deletion
  const handleConfirmDelete = async () => {
    const payload = {
      token: localStorage.getItem("token"),
      usersIds: usersToDelete,
      type: "delete",
    };

    try {
      toast({
        title: "جارٍ الحذف...",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
      const response = await apiRequest(
        `/admin/user/updateUsersActivation`,
        "POST",
        payload
      );

      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: `تم حذف المستخدمين بنجاح.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Remove deleted users from the state
        setUsers((prev) =>
          prev.filter((user) => !usersToDelete.includes(user._id))
        );
      } else {
        toast({
          title: "فشل الحذف",
          description: response.message || "حدث خطأ أثناء حذف المستخدمين.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description:
          error?.response?.data?.message || "حدث خطأ أثناء حذف المستخدمين.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setUsersToDelete([]);
      onDeleteClose();
    }
  };

  // Handle Selecting a User
  const handleSelectUser = (id) => {
    setUsers((prev) =>
      prev.map((user) =>
        user._id === id ? { ...user, isSelected: !user.isSelected } : user
      )
    );
  };

  // Handle Selecting All Users
  const handleSelectAll = () => {
    const allSelected = users.every((user) => user.isSelected);
    setUsers((prev) =>
      prev.map((user) => ({
        ...user,
        isSelected: !allSelected,
      }))
    );
  };

  // Handle Loading State for Packages
  if (packagesLoading) {
    return (
      <Box
        pt={{ base: "130px !important", md: "120px" }}
        p={5}
        maxW="1200px"
        mx="auto"
      >
        <Box textAlign="center" py={10}>
          <Spinner size="xl" />
        </Box>
      </Box>
    );
  }

  // Handle Error State for Packages
  if (packagesError) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">{packagesError}</Text>
      </Box>
    );
  }

  return (
    <Box
      pt={{ base: "130px !important", md: "120px" }}
      p={5}
      maxW="1200px"
      mx="auto"
    >
      <Box
        mb={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontSize="2xl" fontWeight="bold">
          المستخدمين
        </Text>
        <Button
          colorScheme="purple"
          bg="#7e4699"
          color="white"
          _hover={{ bg: "#6b3a8c" }}
          onClick={handleAddUserClick}
        >
          إضافة مستخدم جديد
        </Button>
      </Box>

      {loading ? (
        <Box textAlign="center" py={10}>
          <Spinner size="xl" />
        </Box>
      ) : (
        <Box bg="white" boxShadow="md" borderRadius="md" p={6}>
          {users.length === 0 ? (
            <Text>لا توجد مستخدمين متاحين.</Text>
          ) : (
            <>
              <HStack mb={4}>
                <Button
                  colorScheme="green"
                  bg="#7e4699"
                  color="white"
                  _hover={{ bg: "#6b3a8c" }}
                  onClick={() => handleUpdateUsersActivation("active")}
                >
                  تفعيل
                </Button>
                <Button
                  colorScheme="red"
                  bg="#7e4699"
                  color="white"
                  _hover={{ bg: "#6b3a8c" }}
                  onClick={() => handleUpdateUsersActivation("de_active")}
                >
                  تعطيل
                </Button>
                <Button
                  colorScheme="gray"
                  bg="#7e4699"
                  color="white"
                  _hover={{ bg: "#6b3a8c" }}
                  onClick={() => handleUpdateUsersActivation("delete")}
                >
                  حذف
                </Button>
              </HStack>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        <Checkbox
                          onChange={handleSelectAll}
                          isChecked={users.every((user) => user.isSelected)}
                        />
                      </Th>
                      <Th>الاسم الأول</Th>
                      <Th>الاسم الأخير</Th>
                      <Th>البريد الإلكتروني</Th>
                      <Th>الهاتف</Th>
                      <Th>مدير</Th>
                      <Th>نشط</Th>
                      <Th>الباقة</Th>
                      <Th>موعد انتهاء الاشتراك</Th>
                      <Th>آخر تحديث</Th>
                      <Th>الإجراءات</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {users.map((user) => (
                      <Tr key={user._id}>
                        <Td>
                          <Checkbox
                            isChecked={user.isSelected || false}
                            onChange={() => handleSelectUser(user._id)}
                          />
                        </Td>
                        <Td>{user.firstName}</Td>
                        <Td>{user.lastName}</Td>
                        <Td>{user.email}</Td>
                        <Td dir="ltr">
                          {user.phone?.startsWith("+")
                            ? user.phone
                            : `+${user.phone}`}
                        </Td>
                        <Td>
                          <Checkbox isChecked={user.isAdmin} isReadOnly />
                        </Td>
                        <Td>
                          <Checkbox isChecked={user.isActive} isReadOnly />
                        </Td>
                        <Td>{user.packageId?.title || "غير محدد"}</Td>
                        <Td>
                          {user.subscriptionEndDate
                            ? moment(user.subscriptionEndDate).fromNow()
                            : "غير محدد"}
                        </Td>
                        <Td>{moment(user.updatedAt).fromNow()}</Td>
                        <Td>
                          <Button
                            size="sm"
                            bg="#7e4699"
                            color="white"
                            _hover={{ bg: "#6b3a8c" }}
                            onClick={() => handleEditClick(user)}
                          >
                            تعديل
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      )}

      {/* Modal for Add/Edit User */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="#7e4699" color="white" textAlign="center">
            {editUser
              ? `تعديل المستخدم: ${editUser.firstName} ${editUser.lastName}`
              : "إضافة مستخدم جديد"}
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mb={5}>
              {/* First Name and Last Name in the same row */}
              <FormControl isRequired>
                <FormLabel>الاسم الأول</FormLabel>
                <Input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>الاسم الأخير</FormLabel>
                <Input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </FormControl>
            </SimpleGrid>

            {/* Email */}
            <FormControl isRequired mb={5}>
              <FormLabel>البريد الإلكتروني</FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>

            {/* Phone */}
            <FormControl isRequired mb={5}>
              <FormLabel>الهاتف</FormLabel>
              <PhoneInput
                country={"sa"}
                value={formData.phone}
                placeholder="+966 5xxxxxxx"
                localization={arabicCountries}
                onChange={handlePhoneChange}
                inputStyle={{
                  width: "100%",
                  height: "50px",
                  paddingLeft: "70px",
                  boxSizing: "border-box",
                  width: "100%",
                }}
                containerStyle={{
                  direction: "ltr",
                }}
                buttonStyle={{
                  paddingInline: "10px",
                }}
              />
            </FormControl>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mb={5}>
              {/* Admin */}
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  name="isAdmin"
                  isChecked={formData.isAdmin}
                  onChange={handleChange}
                >
                  مدير
                </Checkbox>
              </FormControl>

              {/* Active */}
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  name="isActive"
                  isChecked={formData.isActive}
                  onChange={handleChange}
                >
                  نشط
                </Checkbox>
              </FormControl>
            </SimpleGrid>

            {/* Package Selection (Visible in both Add and Edit) */}
            <FormControl isRequired mb={5}>
              <FormLabel>الباقة</FormLabel>
              <Select
                icon={<></>}
                placeholder="اختر الباقة"
                name="packageId"
                value={formData.packageId}
                onChange={handleChange}
              >
                {packages.map((pkg) => (
                  <option key={pkg._id} value={pkg._id}>
                    {pkg.title}
                  </option>
                ))}
              </Select>
            </FormControl>

            {/* Subscription Type (Only Visible in Add Mode) */}
            {!editUser && (
              <FormControl isRequired>
                <FormLabel>نوع الاشتراك</FormLabel>
                <Select
                  icon={<></>}
                  placeholder="اختر نوع الاشتراك"
                  name="subscriptionType"
                  value={formData.subscriptionType}
                  onChange={handleChange}
                >
                  <option value="monthly">شهري</option>
                  <option value="quarterly">ربع سنوي</option>
                  <option value="semiAnnually">نصف سنوي</option>
                  <option value="annually">سنوي</option>
                </Select>
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              إلغاء
            </Button>
            <Button
              bg="#7e4699"
              color="white"
              _hover={{ bg: "#6b3a8c" }}
              onClick={handleSubmit}
              isLoading={submitLoading || addUserLoading}
            >
              {editUser ? "تحديث المستخدم" : "إضافة المستخدم"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* AlertDialog for Delete Confirmation */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              تأكيد الحذف
            </AlertDialogHeader>

            <AlertDialogBody>
              هل أنت متأكد أنك تريد حذف المستخدمين المحددين؟ لا يمكن التراجع عن
              هذا الإجراء.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                إلغاء
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                حذف
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Users;
