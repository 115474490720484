import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/ar";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  SimpleGrid,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import apiRequest from "utils/api";

moment.locale("ar");

const CouponsAdmin = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); // For Add/Edit Modal
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure(); // For Delete Confirmation Modal

  const [coupons, setCoupons] = useState([]);
  const [selectedCouponIds, setSelectedCouponIds] = useState([]);

  const [loading, setLoading] = useState(true);
  const [editCoupon, setEditCoupon] = useState(null);
  const [formData, setFormData] = useState({
    code: "",
    discountValue: "",
    maxUses: "",
    expirationDate: "",
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  // Fetch coupons from API
  const fetchCoupons = async () => {
    setLoading(true);
    try {
      const response = await apiRequest(`/admin/coupon/getCoupons`, "POST");

      if (response.code === 200) {
        setCoupons(
          response.coupons.map((coupon) => ({
            ...coupon,
            isSelected: false, // Initialize selection state
          }))
        );
      } else {
        toast({
          title: "خطأ",
          description: response.message || "فشل جلب الكوبونات.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: "حدث خطأ أثناء جلب الكوبونات.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  // Open Add/Edit Modal
  const handleEditClick = (coupon) => {
    setEditCoupon(coupon);
    setFormData({
      code: coupon.code,
      discountValue: coupon.discountValue,
      maxUses: coupon.maxUses,
      expirationDate: moment(coupon.expirationDate).format("YYYY-MM-DD"), // Format for date input
    });
    onOpen();
  };

  const handleAddCouponClick = () => {
    setEditCoupon(null);
    setFormData({
      code: "",
      discountValue: "",
      maxUses: "",
      expirationDate: "",
    });
    onOpen();
  };

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Submit form to add or edit coupon
  const handleSubmit = async () => {
    const payload = {
      ...formData,
      expirationDate: moment(formData.expirationDate).toISOString(),
    };
    const url = editCoupon
      ? `/admin/coupon/editCoupon/${editCoupon._id}`
      : `/admin/coupon/createCoupon`;
    const method = editCoupon ? "PUT" : "POST";

    try {
      setSubmitLoading(true);
      const response = await apiRequest(url, method, payload);

      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: "تم إنشاء الكوبون بنجاح.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setCoupons((prevCoupons) => {
          if (editCoupon) {
            // Edit
            return prevCoupons.map((coupon) =>
              coupon._id === editCoupon._id
                ? {
                    ...coupon,
                    ...payload,
                  }
                : coupon
            );
          } else {
            return [{ ...response.coupon, isSelected: false }, ...prevCoupons];
          }
        });
        onClose();
      } else if (response.code === 400) {
        toast({
          title: "خطأ",
          description: response.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description:
          error?.response?.data?.message || "حدث خطأ أثناء إنشاء الكوبون.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  // Handle selection of coupons
  const handleSelectCoupon = (id) => {
    setCoupons((prev) =>
      prev.map((coupon) =>
        coupon._id === id
          ? { ...coupon, isSelected: !coupon.isSelected }
          : coupon
      )
    );
  };

  // Open the confirmation window upon deletion
  const updateCouponStatus = (status) => {
    const selectedCouponIds = coupons
      .filter((coupon) => coupon.isSelected)
      .map((coupon) => coupon._id);

    if (selectedCouponIds.length === 0) {
      toast({
        title: "لم يتم تحديد قسائم",
        description: "يرجى اختيار القسائم أولاً.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (status === "delete") {
      setSelectedCouponIds(selectedCouponIds);
      onDeleteOpen();
    } else {
      executeCouponStatusUpdate(status, selectedCouponIds);
    }
  };

  // Perform deletion after confirmation
  const handleConfirmDelete = async () => {
    executeCouponStatusUpdate("delete", selectedCouponIds);
    onDeleteClose();
  };

  // A function to perform a status update based on status
  const executeCouponStatusUpdate = async (status, couponIds) => {
    let endpoint, method;

    if (status === "activate") {
      endpoint = "/admin/coupon/activateCoupons";
      method = "PUT";
    } else if (status === "deactivate") {
      endpoint = "/admin/coupon/deactivateCoupons";
      method = "PUT";
    } else if (status === "delete") {
      endpoint = "/admin/coupon/deleteCoupons";
      method = "DELETE";
    }

    try {
      toast({
        title: "جار المعالجة...",
        status: "info",
        duration: 2000,
        isClosable: true,
      });

      const response = await apiRequest(endpoint, method, { couponIds });
      if (response.code === 200) {
        toast({
          title: "نجاح",
          description: `تم ${
            status === "activate"
              ? "تفعيل"
              : status === "deactivate"
              ? "تعطيل"
              : "حذف"
          } القسائم بنجاح.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        if (status === "activate" || status === "deactivate") {
          setCoupons((prevCoupons) =>
            prevCoupons.map((coupon) => ({
              ...coupon,
              isActive: couponIds.includes(coupon._id)
                ? status === "activate"
                : coupon.isActive,
              isSelected: false,
            }))
          );
        } else {
          setCoupons((prevCoupons) =>
            prevCoupons.filter((coupon) => !couponIds.includes(coupon._id))
          );
        }
      } else {
        toast({
          title: "خطأ",
          description: response.message || "حدث خطأ ما.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: error?.response?.data?.message || "حدث خطأ أثناء التحديث.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSelectAll = () => {
    const allSelected = coupons.every((coupon) => coupon.isSelected);
    setCoupons((prev) =>
      prev.map((coupon) => ({
        ...coupon,
        isSelected: !allSelected,
      }))
    );
  };

  // Loading state display
  if (loading) {
    return (
      <Box
        pt={{ base: "130px !important", md: "120px" }}
        p={5}
        maxW="1200px"
        mx="auto"
      >
        <Box textAlign="center" py={10}>
          <Spinner size="xl" />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        pt={{ base: "130px !important", md: "120px" }}
        p={5}
        maxW="1200px"
        mx="auto"
      >
        <Box
          mb={6}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontSize="2xl" fontWeight="bold">
            إدارة الكوبونات
          </Text>
          <Button
            colorScheme="purple"
            bg="#7e4699"
            color="white"
            _hover={{ bg: "#6b3a8c" }}
            onClick={handleAddCouponClick}
          >
            إضافة كوبون جديد
          </Button>
        </Box>

        <HStack mb={4}>
          <Button
            colorScheme="purple"
            _hover={{ bg: "#6b3a8c" }}
            bg="#7e4699"
            color="white"
            onClick={() => updateCouponStatus("activate")}
          >
            تفعيل
          </Button>
          <Button
            colorScheme="purple"
            _hover={{ bg: "#6b3a8c" }}
            bg="#7e4699"
            color="white"
            onClick={() => updateCouponStatus("deactivate")}
          >
            تعطيل
          </Button>

          <Button
            colorScheme="purple"
            _hover={{ bg: "#6b3a8c" }}
            bg="#7e4699"
            color="white"
            onClick={() => updateCouponStatus("delete")}
          >
            حذف
          </Button>
        </HStack>

        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    onChange={handleSelectAll}
                    isChecked={coupons.every((coupon) => coupon.isSelected)}
                  />
                </Th>
                <Th>رمز الكوبون</Th>
                <Th>قيمة الخصم</Th>
                <Th>عدد الاستخدامات</Th>
                <Th>فعال</Th>
                <Th>تاريخ انتهاء الصلاحية</Th>
                <Th>الإجراءات</Th>
              </Tr>
            </Thead>
            <Tbody>
              {coupons.map((coupon) => (
                <Tr key={coupon._id}>
                  <Td>
                    <Checkbox
                      isChecked={coupon.isSelected || false}
                      onChange={() => handleSelectCoupon(coupon._id)}
                    />
                  </Td>
                  <Td>{coupon.code}</Td>
                  <Td>{coupon.discountValue}</Td>
                  <Td>{coupon.maxUses}</Td>
                  <Td>
                    <Checkbox isChecked={coupon.isActive} isReadOnly />
                  </Td>
                  <Td>{moment(coupon.expirationDate).fromNow()}</Td>
                  <Td>
                    <Button
                      size="sm"
                      bg="#7e4699"
                      color="white"
                      _hover={{ bg: "#6b3a8c" }}
                      onClick={() => handleEditClick(coupon)}
                    >
                      تعديل
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {/* Modal for Add/Edit Coupon */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="#7e4699" color="white" textAlign="center">
            {editCoupon
              ? `تعديل الكوبون: ${editCoupon.code}`
              : "إضافة كوبون جديد"}
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <SimpleGrid columns={2} spacing={5} mb={5}>
              <FormControl>
                <FormLabel>رمز الكوبون</FormLabel>
                <Input
                  placeholder="رمز الكوبون"
                  name="code"
                  value={formData.code}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>قيمة الخصم</FormLabel>
                <Input
                  placeholder="قيمة الخصم %"
                  name="discountValue"
                  value={formData.discountValue}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>عدد الاستخدامات</FormLabel>
                <Input
                  placeholder="عدد الاستخدامات"
                  name="maxUses"
                  value={formData.maxUses}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>تاريخ انتهاء الصلاحية</FormLabel>
                <Input
                  type="date"
                  name="expirationDate"
                  value={formData.expirationDate}
                  onChange={handleChange}
                />
              </FormControl>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              إلغاء
            </Button>
            <Button
              bg="#7e4699"
              color="white"
              _hover={{ bg: "#6b3a8c" }}
              onClick={handleSubmit}
              isLoading={submitLoading}
            >
              {editCoupon ? "تحديث الكوبون" : "إضافة الكوبون"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>تأكيد الحذف</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              هل أنت متأكد أنك تريد حذف القسائم المحددة؟ لا يمكن التراجع عن هذا
              الإجراء.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onDeleteClose}>
              إلغاء
            </Button>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              تأكيد الحذف
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CouponsAdmin;
