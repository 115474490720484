import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  useToast,
  Box,
  HStack,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  SimpleGrid,
  GridItem,
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Collapse,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  RadioGroup,
  Stack,
  Radio,
  Checkbox,
  ModalFooter,
} from "@chakra-ui/react";

import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { FaUserPlus } from "react-icons/fa6";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";
import { CircleLoader } from "react-spinners";
import * as XLSX from "xlsx";

const countryData = {
  "+1": { country: "USA/Canada", iso: "US/CA" },
  "+20": { country: "Egypt", iso: "EG" },
  "+44": { country: "UK", iso: "GB" },
  "+966": { country: "Saudi Arabia", iso: "SA" },
  "+971": { country: "UAE", iso: "AE" },
  "+212": { country: "Morocco", iso: "MA" },
  "+33": { country: "France", iso: "FR" },
  "+49": { country: "Germany", iso: "DE" },
  "+81": { country: "Japan", iso: "JP" },
  "+86": { country: "China", iso: "CN" },
  "+91": { country: "India", iso: "IN" },
  "+39": { country: "Italy", iso: "IT" },
  "+34": { country: "Spain", iso: "ES" },
  "+61": { country: "Australia", iso: "AU" },
  "+7": { country: "Russia", iso: "RU" },
  "+55": { country: "Brazil", iso: "BR" },
  "+82": { country: "South Korea", iso: "KR" },
  "+27": { country: "South Africa", iso: "ZA" },
  "+90": { country: "Turkey", iso: "TR" },
  "+60": { country: "Malaysia", iso: "MY" },
  "+62": { country: "Indonesia", iso: "ID" },
  "+234": { country: "Nigeria", iso: "NG" },
  "+353": { country: "Ireland", iso: "IE" },
  "+46": { country: "Sweden", iso: "SE" },
  "+64": { country: "New Zealand", iso: "NZ" },
  "+48": { country: "Poland", iso: "PL" },
  "+31": { country: "Netherlands", iso: "NL" },
  "+45": { country: "Denmark", iso: "DK" },
  "+41": { country: "Switzerland", iso: "CH" },
};

const countryTranslations = {
  "USA/Canada": "الولايات المتحدة / كندا",
  UK: "المملكة المتحدة",
  Egypt: "مصر",
  "Saudi Arabia": "السعودية",
  UAE: "الإمارات",
  Morocco: "المغرب",
  France: "فرنسا",
  Germany: "ألمانيا",
  Japan: "اليابان",
  China: "الصين",
  India: "الهند",
  Italy: "إيطاليا",
  Spain: "إسبانيا",
  Australia: "أستراليا",
  Russia: "روسيا",
  Brazil: "البرازيل",
  "South Korea": "كوريا الجنوبية",
  "South Africa": "جنوب أفريقيا",
  Turkey: "تركيا",
  Malaysia: "ماليزيا",
  Indonesia: "إندونيسيا",
  Nigeria: "نيجيريا",
  Ireland: "أيرلندا",
  Sweden: "السويد",
  "New Zealand": "نيوزيلندا",
  Poland: "بولندا",
  Netherlands: "هولندا",
  Denmark: "الدنمارك",
  Switzerland: "سويسرا",
};

const Sections = ({ adminSection }) => {
  const user = useSelector((state) => state.user?.data);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const contactFileRef = useRef();
  const toast = useToast();

  const [sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const [fileContent, setFileContent] = useState(null);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isDownloadOrderModalOpen, setIsDownloadOrderModalOpen] =
    useState(false);

  const [selectedFormat, setSelectedFormat] = useState("txt");
  const [selectedSection, setSelectedSection] = useState(null);
  const [loading, setLoading] = useState(true);

  const [addContactsLoading, setAddContactsLoading] = useState(false);
  const [newSctionLoading, setNewSctionLoading] = useState(false);
  const [orderNumbers, setOrderNumbers] = useState([]);

  const [sectionDelete, setSectionDelete] = useState(null);
  const [expandedSections, setExpandedSections] = useState([]);

  const fetchSections = async () => {
    try {
      setLoading(true);

      if (adminSection) {
        const response = await apiRequest(
          "/admin/sections/getAdminSections",
          "POST"
        );

        const orderNums = response?.phoneNumberSections;
        let unOrderNums = new Set();
        Object.keys(orderNums).map((key) => {
          orderNums[key].forEach((num) => unOrderNums.add(num));
        });
        unOrderNums = [...unOrderNums];

        return setSections([
          {
            name: "القسم الشامل",
            id: 1,
            allContacts: unOrderNums || [],
            ordersContacts: orderNums || {},
          },
        ]);
      }

      const response = await apiRequest("/sections/getSections", "POST");
      let sections = response?.sections?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      sections = sections?.map((section) => ({
        name: section.title,
        id: section._id,
        allContacts: section?.contacts?.allContacts || [],
        ordersContacts: section?.contacts?.ordersContacts || {},
      }));

      if (response.code === 200) {
        setSections(sections);
      }
    } catch (error) {
      toast({
        title: "خطأ",
        description: "فشل في جلب الأقسام",
        status: "error",
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSections();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const newNumbers = e.target.result
        .split("\n")
        .map((num) => num.trim())
        .filter((num) => num !== "");
      setFileContent(newNumbers);
    };
    reader.readAsText(file);
  };

  const handleMergeFileChange = async (e, sectionId) => {
    const file = e.target.files[0];
    if (!file) return;
    e.target.value = null;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const newNumbers = e.target.result
        .split("\n")
        .map((num) => num.trim())
        .filter((num) => num !== "");

      try {
        setAddContactsLoading(true);
        const response = await apiRequest("/sections/addContacts", "POST", {
          sectionId: sectionId,
          contacts: newNumbers,
        });

        if (response.code === 200) {
          await fetchSections();
          toast({
            title: "تم دمج الأرقام بنجاح",
            status: "success",
            duration: 3000,
          });
        } else {
          toast({
            title: "حدث خطأ",
            description: response.message,
            status: "error",
            duration: 3000,
          });
        }
      } catch (e) {
        toast({
          title: "حدث خطأ",
          description: e?.response?.data?.message || e.message,
          status: "error",
          duration: 3000,
        });
      } finally {
        setAddContactsLoading(false);
      }
    };
    reader.readAsText(file);
  };

  const handleAddSection = async () => {
    if (sectionName.trim() === "" || !fileContent) {
      toast({
        title: "اسم القسم وملف الأرقام مطلوبان",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    if (sections.find((section) => section.name === sectionName)) {
      toast({
        title: "القسم موجود بالفعل",
        status: "error",
        duration: 3000,
      });
      return;
    }

    try {
      setNewSctionLoading(true);
      const response = await apiRequest("/sections/newSection", "POST", {
        title: sectionName,
        contacts: fileContent,
      });

      if (response.code === 200) {
        setSectionName("");
        setFileContent(null);
        if (contactFileRef.current) {
          contactFileRef.current.value = null;
        }

        toast({
          title: "تم إنشاء القسم بنجاح",
          status: "success",
          duration: 3000,
        });
        await fetchSections();
      } else {
        toast({
          title: "حدث خطأ",
          description: response.message,
          status: "error",
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: "حدث خطأ",
        description: e?.response?.data?.message || e.message,
        status: "error",
        duration: 3000,
      });
    } finally {
      setNewSctionLoading(false);
    }
  };

  const handleDeleteSection = async () => {
    if (!sectionDelete) return;

    try {
      setLoading(true);
      const response = await apiRequest("/sections/delSections", "POST", {
        sectionId: sectionDelete,
      });

      if (response.code === 200) {
        toast({
          title: "تم حذف القسم بنجاح",
          status: "info",
          duration: 3000,
        });
        await fetchSections();
      } else {
        toast({
          title: "حدث خطأ",
          description: response.message,
          status: "error",
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: "حدث خطأ",
        description: e?.response?.data?.message || e.message,
        status: "error",
        duration: 3000,
      });
    } finally {
      onClose();
    }
  };

  const handleConfirmDownload = async (order = false) => {
    const exportData = order
      ? orderNumbers.join("\n")
      : selectedSection.allContacts.join("\n");

    if (selectedFormat === "txt") {
      const blob = new Blob([exportData], { type: "text/plain;charset=utf-8" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${selectedSection.name}_numbers.txt`;
      link.click();
    } else if (selectedFormat === "excel") {
      const numbersArray = exportData.split("\n");

      // تأكد من توفر بيانات الدول countryData
      const countryCodes = Object.keys(countryData).sort(
        (a, b) => b.length - a.length
      );

      const dataForExcel = numbersArray.map((number) => {
        let countryCode = null;
        let countryName = "Unknown";
        let countryISO = "Unknown";

        // البحث عن رمز الدولة المناسب
        for (let code of countryCodes) {
          if (number.startsWith(code)) {
            countryCode = code;
            countryName = countryData[code].country;
            countryISO = countryData[code].iso;
            break;
          }
        }

        return {
          PhoneNumber: number,
          Country: countryName,
          CountryISO: countryISO,
          CountryCode: countryCode || "Unknown",
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // إنشاء وتحميل ملف Excel
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${selectedSection.name}_numbers.xlsx`;
      link.click();
    }

    if (order) {
      setOrderNumbers([]);
      setIsDownloadOrderModalOpen(false);
    } else {
      setIsDownloadModalOpen(false);
    }
  };

  const handleDownloadNumbers = (section) => {
    setSelectedSection(section);
    setIsDownloadModalOpen(true);
  };

  const showOrdersDetails = (section) => {
    setExpandedSections((prev) =>
      prev.includes(section.id)
        ? prev.filter((id) => id !== section.id)
        : [...prev, section.id]
    );
  };

  const handleDownloadOrderNumbers = (numbers) => {
    setOrderNumbers(numbers);
    setIsDownloadOrderModalOpen(true);
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose();
          setSectionDelete(null);
        }}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              تأكيد الحذف
            </AlertDialogHeader>

            <AlertDialogBody>
              هل أنت متأكد أنك تريد حذف هذا القسم؟
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  onClose();
                  setSectionDelete(null);
                }}
              >
                إلغاء
              </Button>
              <Button
                colorScheme="red"
                loadingText="يتم الحذف"
                isLoading={loading}
                onClick={handleDeleteSection}
                ml={3}
              >
                حذف
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Card mb={{ base: "0px", lg: "20px" }} px={10}>
        <VStack spacing={4} pt={4} pb={6}>
          {!adminSection && (
            <FormControl py={3}>
              <FormLabel>إنشاء قسم جديد</FormLabel>
              <SimpleGrid columns={{ md: 6 }} spacing={4}>
                <GridItem colSpan={{ base: 1, md: 4 }}>
                  <Input
                    placeholder="اسم القسم"
                    value={sectionName}
                    width={"100%"}
                    onChange={(e) => setSectionName(e.target.value)}
                  />
                </GridItem>
                <Input
                  type="file"
                  accept=".txt"
                  onChange={handleFileChange}
                  display="none"
                  id="file-upload"
                  ref={contactFileRef}
                />
                <Button
                  as="label"
                  htmlFor="file-upload"
                  colorScheme="teal"
                  cursor={"pointer"}
                >
                  رفع ملف الأرقام
                </Button>
                <Button
                  isLoading={newSctionLoading}
                  loadingText="يتم إنشاء القسم"
                  colorScheme="blue"
                  onClick={handleAddSection}
                >
                  إضافة
                </Button>
              </SimpleGrid>
            </FormControl>
          )}

          <Box w="100%">
            <Text fontWeight="bold" mb={4}>
              الأقسام
            </Text>
            <Box w="100%" overflowX="auto" maxHeight="400px" overflowY="auto">
              {loading ? (
                <Flex direction="column" justify="center" align="center">
                  <CircleLoader color="#7e4699" />
                  <Text
                    mt={4}
                    fontSize={"24px"}
                    fontWeight="bold"
                    textShadow="0px 2px 4px rgba(0, 0, 0, 0.3)"
                  >
                    جاري التحميل...
                  </Text>
                </Flex>
              ) : sections.length === 0 ? (
                <Text textAlign="center" color="gray.500" py={4}>
                  لا توجد أقسام حاليًا. يمكنك إضافة قسم جديد باستخدام النموذج
                  أعلاه.
                </Text>
              ) : (
                <Table variant="striped" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th textAlign={"center"}>اسم القسم</Th>
                      <Th textAlign={"center"}>عدد الأرقام</Th>
                      <Th textAlign={"center"}>تصنيفات</Th>
                      <Th textAlign={"center"}>العمليات</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sections.map((section) => (
                      <React.Fragment key={section.id}>
                        <Tr>
                          <Td fontWeight="bold" textAlign={"center"}>
                            {section.name}
                          </Td>
                          <Td color="gray.500" textAlign={"center"}>
                            {section.allContacts.length}
                          </Td>
                          <Td textAlign={"center"}>
                            <Button
                              fontWeight={"bold"}
                              color="#7e4699"
                              variant="ghost"
                              onClick={() => showOrdersDetails(section)}
                            >
                              {expandedSections.includes(section.id)
                                ? "إخفاء"
                                : "عرض"}
                            </Button>
                          </Td>
                          <Td textAlign={"center"}>
                            <HStack
                              spacing={3}
                              display={"flex"}
                              justifyContent={"center"}
                              align={"center"}
                            >
                              <Button
                                size="sm"
                                colorScheme="green"
                                onClick={() =>
                                  section.allContacts.length &&
                                  handleDownloadNumbers(section)
                                }
                                leftIcon={<DownloadIcon />}
                              >
                                تحميل الأرقام
                              </Button>
                              {!adminSection && (
                                <>
                                  <Input
                                    type="file"
                                    accept=".txt"
                                    onChange={(e) =>
                                      handleMergeFileChange(e, section.id)
                                    }
                                    display="none"
                                    id={`upload-merge-${section.id}`}
                                  />
                                  <Button
                                    as="label"
                                    htmlFor={`upload-merge-${section.id}`}
                                    size="sm"
                                    colorScheme="teal"
                                    cursor={"pointer"}
                                    leftIcon={<FaUserPlus />}
                                    isLoading={addContactsLoading}
                                    loadingText={"يتم الإضافه"}
                                  >
                                    اضف ارقام
                                  </Button>
                                  <IconButton
                                    icon={<DeleteIcon />}
                                    colorScheme="red"
                                    size="sm"
                                    onClick={() => {
                                      setSectionDelete(section?.id);
                                      onOpen();
                                    }}
                                  />
                                </>
                              )}
                            </HStack>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td colSpan={4} p={0}>
                            <Collapse
                              in={expandedSections.includes(section.id)}
                            >
                              <Box p={4} bg="gray.50">
                                <Text fontSize={"xl"} ps={"40px"} py={4}>
                                  اسم القسم:{" "}
                                  <Text
                                    fontWeight={"bold"}
                                    as={"span"}
                                    fontSize={"17px"}
                                  >
                                    {section?.name}
                                  </Text>
                                </Text>
                                <Table variant="simple" size="sm">
                                  <Thead>
                                    <Tr>
                                      <Th textAlign="center">الدولة</Th>
                                      <Th textAlign="center">عدد الأرقام</Th>
                                      <Th textAlign="center">تحميل الارقام</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {Object.entries(section.ordersContacts).map(
                                      ([country, numbers]) => (
                                        <Tr key={country}>
                                          <Td textAlign="center">
                                            {countryTranslations[country] ||
                                              country}
                                          </Td>
                                          <Td textAlign="center">
                                            {numbers.length}
                                          </Td>
                                          <Td textAlign="center">
                                            <Button
                                              size="sm"
                                              colorScheme="green"
                                              onClick={() =>
                                                handleDownloadOrderNumbers(
                                                  section?.ordersContacts[
                                                    country
                                                  ]
                                                )
                                              }
                                              leftIcon={<DownloadIcon />}
                                            >
                                              تحميل الأرقام
                                            </Button>
                                          </Td>
                                        </Tr>
                                      )
                                    )}
                                  </Tbody>
                                </Table>
                              </Box>
                            </Collapse>
                          </Td>
                        </Tr>
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Box>
          </Box>
        </VStack>
      </Card>

      {/* Download Modal */}
      <Modal
        isCentered
        isOpen={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader ms={6}>اختر صيغة الملف</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={setSelectedFormat} value={selectedFormat}>
              <Stack direction="column">
                <Radio value="txt">TXT.</Radio>
                <Radio value="excel">Excel.</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>

          <ModalFooter gap={3}>
            <Button
              colorScheme="green"
              mr={3}
              onClick={() => handleConfirmDownload()}
            >
              تنزيل
            </Button>
            <Button
              colorScheme="red"
              onClick={() => setIsDownloadModalOpen(false)}
            >
              إلغاء
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Download Order Modal */}
      <Modal
        isCentered
        isOpen={isDownloadOrderModalOpen}
        onClose={() => setIsDownloadOrderModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader ms={6}>اختر صيغة الملف</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={setSelectedFormat} value={selectedFormat}>
              <Stack direction="column">
                <Radio value="txt">TXT.</Radio>
                <Radio value="excel">Excel.</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>

          <ModalFooter gap={3}>
            <Button
              colorScheme="green"
              mr={3}
              onClick={() => handleConfirmDownload(true)}
            >
              تنزيل
            </Button>
            <Button
              colorScheme="red"
              onClick={() => setIsDownloadOrderModalOpen(false)}
            >
              إلغاء
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Sections;
